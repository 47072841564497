<template>
  <div style="text-align:center">
    <h1 style="color:#409EFF">智能检测平台</h1>
    <el-form ref="form" :model="user" label-width="80px" :rules="rules" class="form">
        <el-form-item class="item" prop="username">
            <el-input placeholder="用户名" v-model="user.username" class="input"></el-input>
        </el-form-item>
        <el-form-item class="item" prop="password">
            <el-input placeholder="密码" v-model="user.password" class="input" type="password"></el-input>
        </el-form-item>
        <el-form-item>
            <el-button type="primary" @click="login('ruleForm')" class="login_button">登录</el-button>
        </el-form-item>
    </el-form>
    <div class="dl" @click="gotoZc">去注册</div>
  </div>
</template>

<script>
import Axios from 'axios'

export default {
    data(){
        var checkUsername = (rule, value, callback) => {
            if (!value) {
                return callback(new Error('账号不能为空'));
            }else{
                callback();
            }
        };
        var checkpassword = (rule, value, callback) => {
            if (!value) {
                return callback(new Error('密码不能为空'));
            }else{
                callback();
            }
        };
        return{
            
            user:{
                username:'',
                password:'',
            },
            rules: {
                username: [
                    { validator: checkUsername, trigger: 'blur' }
                ],
                password: [
                    { validator: checkpassword, trigger: 'blur' }
                ]
            }
        }
    },
    methods:{
        gotoZc(){
            this.$router.push('/register')
        },
        login(formName) {
            // this.$refs[formName].validate((valid) => {
            if (1) {
                Axios.post('/user/login',this.user)
                .then((resp)=>{
                    console.log(resp)
                    if(resp.data.status==200){
                        this.$message({
                            message: '登录成功',
                            type: 'success'
                        });
                        this.$router.push('/home')
                        let user_str = JSON.stringify(resp.data.user)
                        localStorage.setItem('user', user_str)
                        localStorage.setItem('token', resp.data.jwt)
                        this.$store.commit('setUser', resp.data.user)
                        
                    }else{
                        this.$message.error(resp.data.msg)
                    }
                    
                })
                .catch((err)=>{
                    console.log('network submit!!');
                    console.log(err);
                    
                })
            } else {
                console.log('error submit!!');
                return false;
            }
            // });
        },
    }
}
</script>

<style>
.form{
    width: 500px;
    margin: 0 auto;
}
.input{
    border-radius: 30%;
}
.login_button{
    width: 100%;
    border-radius: 50%;
}
.dl{
    color:#248af1;
    text-align: right;;
    width: 500px;
    margin: 0 auto;
}
</style>