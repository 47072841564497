<template>
    <div class="reg">
        <h2>注册新用户</h2>
        <el-tabs v-model="activeName" class="login-tabs">
            <el-tab-pane label="微信快速注册" name="0">
                <div class="wxlogin" id="app_wx"> </div>
            </el-tab-pane>
            <el-tab-pane label="普通注册" name="1">
                <el-form :model="reg_user" status-icon :rules="rules" 
                ref="ruleForm" label-width="100px" class="demo-ruleForm"
                style="width:400px; margin:20px auto;">
                    <el-form-item label="账号:" prop="username">
                        <el-input v-model="reg_user.username" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="密码:" prop="password1">
                        <el-input type="password" v-model="reg_user.password1" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="确认密码:" prop="password2">
                        <el-input type="password" v-model="reg_user.password2" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="手机:" prop="mobile">
                        <el-input v-model="reg_user.mobile" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="验证码:" prop="code" style="text-align:left;">
                        <el-input v-model="reg_user.code" autocomplete="off" style="width:50%"></el-input>
                        <img :src="url" alt="" @click="imgCodeUrl" class="imgcode">
                    </el-form-item>
                    <div style="height:50px;line-height:50px;text-align:center;">
                        <el-checkbox v-model="reg_user.agree">同意勾选
                            <router-link to="/agree">用户协议</router-link>
                        </el-checkbox>
                    </div>
                    <el-form-item>
                        <el-button @click="flag=false">取 消</el-button>
                        <el-button type="primary" @click="Register('ruleForm')">注册</el-button>
                    </el-form-item>
                </el-form>
            </el-tab-pane>
        </el-tabs>

    </div>
</template>

<script>
import {mapState,mapMutations} from 'vuex'
import {v4 as uuid4} from 'uuid'
import axios from 'axios';
export default {
    mounted(){
        
        if(this.$store.state.user.username){
            this.$router.push('/')
        }
        setTimeout(()=>{
            console.log('微信登录11111111111');
            this.wechatLogin()
        },200)
        
    },
    data(){
        var validateUsername = (rule, value, callback) => {
            if (!value) {
            return callback(new Error('用户名不能为空'));
            }
            let usernameRule = /^[a-zA-Z]\w{4,14}$/
            if(!usernameRule.test(value)){
                return callback(new Error('用户名必须以字母开头,由8-16位字母数字组成'));
            }
            return callback()
        };
        var validatePassword1 = (rule, value, callback) => {
            if (!value) {
            return callback(new Error('密码不能为空'));
            }
            let r = /^\w{5,15}$/
            if(!r.test(value)){
                return callback(new Error('密码由5-15位字母数字组成'));
            }

            return callback()
        };
        var validatePassword2 = (rule, value, callback) => {
            if (!value) {
            return callback(new Error('密码不能为空'));
            }
            let r = /^\w{5,15}$/
            if(!r.test(value)){
                return callback(new Error('密码由5-15位字母数字组成'));
            }
            if(this.reg_user.password1 != this.reg_user.password2){
                return callback(new Error('两次密码不一致'));
            }

            return callback()
        };
        var validateMobile = (rule, value, callback) => {
            if (!value) {
            return callback(new Error('手机号不能为空'));
            }
            let r = /^1[3-9]\d{9}$/
            if(!r.test(value)){
                return callback(new Error('手机号格式不正确'));
            }

            return callback()
        };
        var validateCode = (rule, value, callback) => {
            if (!value) {
            return callback(new Error('验证码不能为空'));
            }
            let r = /^\w{4}$/
            if(!r.test(value)){
                return callback(new Error('验证码格式不正确'));
            }

            axios.post('/user/img/'+this.uuid + '/', {code:this.reg_user.code})
            .then(resp=>{
                console.log(resp)
                if(resp.data.code == 200){
                    // this.$message({message: resp.data.msg,type: 'success'});
                    callback()
                }else{
                    // this.$message.error(resp.data.msg);
                    callback(new Error(resp.data.msg));
                }
            })
            .catch(err=>{
                console.log(err)
            })
            
        };
        return{
            activeName:"0",
            uuid:'',
            url:'',
            flag:false,
            reg_user: {
                username: '',
                password1: '',
                mobile:'',
                code:'',
                password2:'',
                agree:false,
            },
            rules: {
                username: [
                    { validator: validateUsername, trigger: 'blur' }
                ],
                password1: [
                    { validator: validatePassword1, trigger: 'blur' }
                ],
                password2: [
                    { validator: validatePassword2, trigger: 'blur' }
                ],
                mobile: [
                    { validator: validateMobile, trigger: 'blur' }
                ],
                code: [
                    { validator: validateCode, trigger: 'blur' }
                ],
            }
        }
    },
    created(){
        // this.flag = this.login_flag;
        this.imgCodeUrl();
    },
    computed:{
        ...mapState(['reg_flag']),//映射登录标记位
    },
    methods:{
        wechatLogin(){
            console.log('微信扫码登录');
            var obj = new WxLogin({
                self_redirect:false,
                id:"app_wx", 
                appid: "wxcf68593de8ca98c5", 
                scope: "snsapi_login", 
                redirect_uri: "http://www.biocodehub.com/user/wxtoken",
                state: "STATE",
                style: "",
                href: ""
            });
        },
        gotoDl(){
            this.$router.push('/')
        },
        imgCodeUrl(){
            this.uuid = uuid4();
            this.url = '/user/img/'+this.uuid
        },
        Register(formName) {
            this.$refs[formName].validate((valid) => {
            if (valid) {
                axios.post("user/register", this.reg_user)
                .then(resp=>{
                    console.log(resp)
                    if(resp.data.code == 200){
                        this.$message({message: resp.data.msg,type: 'success'});
                        let userinfo = resp.data.user
                        let token = resp.data.token
                        localStorage.setItem('user', JSON.stringify(userinfo))
                        localStorage.setItem('token', token)
                        this.$store.commit('setUser', userinfo)
                        this.flag=false
                        this.$router.push('/')
                    }else{
                        this.$message.error(resp.data.msg);
                    }
                })
                .catch(err=>{
                    console.log(err)
                })
            } else {
                console.log('error submit!!');
                return false;
            }
            });
        },
        ...mapMutations(['setRegFlag']),
        
    },
    watch:{
        reg_flag: function(v){
            this.flag = v;
        },
        flag:function(v){
            console.log(v);
            this.setRegFlag(v);
        },
        activeName:function(v){
            if(v == '0'){
                console.log('微信登录');
                this.wechatLogin()
            }
        }
    }
}
</script>

<style>
.reg{
    text-align: center;
}
.imgcode{
    width: 45%;
    position: absolute;
    height: 40px;
    cursor: pointer;
}
.dl{
    color:#248af1;
    text-align: right;;
    width: 400px;
    margin: 0 auto;
}

.login-tabs{
    width: 500px;
    margin: 20px auto; 
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 20px;
    padding: 20px;
    height: 500px;
}

.wxin{
    width: 500px;
    height: 500px;
}
</style>