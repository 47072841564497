<template>
  <div>
    <div class="copy-content">
        <!-- 复制按钮 -->
        <div
            class="copy-header"
            v-clipboard:copy="pom"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
          >
          code
          <i class="el-icon-document-copy myicon"></i>
        </div>
      </div>
      <prism-editor
        class="my-editor"
        v-model="pom"
        :highlight="highlighter"
        readonly
        line-numbers
      ></prism-editor>
  </div>
</template>
 
<script>
// import Prism Editor
import { PrismEditor } from "vue-prism-editor";
import "vue-prism-editor/dist/prismeditor.min.css";
 
// import highlighting library
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/themes/prism-tomorrow.css";

export default {
  components: {
    PrismEditor
  },
  props:{
     pom: {
    	// 定义接收的类型 还可以定义多种类型 [String, Undefined, Number]
    	// 如果required为true,尽量type允许undefined类型，因为传递过来的参数是异步的。或者设置默认值。
		type: String,
		// 定义是否必须传
		required: true,
		// 定义默认值
		default: '暂无'
	},
  },
  data() {
    return {
      activeName: "first",
    //   pom:
    //     "img = cv2.imread('test1.png') \n" +
    //     "gray_img = cv2.cvtColor(img, cv2.COLOR_BGR2GRAY) \n" +
    //     "ret, binary_img = cv2.threshold(gray_img, 60, 255, cv2.THRESH_BINARY) \n" +
    //     "ret2, binary_img2 = cv2.threshold(gray_img, 60, 255, cv2.THRESH_BINARY_INV) \n" +
    //     "ret3 = cv2.adaptiveThreshold(gray_img, 255, cv2.ADAPTIVE_THRESH_GAUSSIAN_C, cv2.THRESH_BINARY, 11, 2) \n",
      code: null,
      success: false,
    };
  },
  methods: {
    onCopy(){
      this.$message({
        message: '复制成功',
        type: 'success'
      });
    },
    onError(){
        this.$message.error('复制失败');
    },
    handleClick(){

    },
    highlighter(code) {
      return highlight(code, languages.js);
    }
  }
};
</script>
 
 
<style scoped>
.copy-header{
  background-color: #2d2d2d;
  color: white;
  width: 90%;
  margin: 0 auto;
  border-bottom: 1px solid grey;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  text-align: left;
  text-indent: 1em;
}

.markdown-body p {
  font-size: 14px;
  line-height: 28px;
  word-wrap: break-word;
  color: rgba(0, 0, 0, 0.7);
}
* {
  font-family: Microsoft Yahei, PingFang SC, Hiragino Sans GB, tahoma, arial;
  box-sizing: border-box;
  font-size: 14px;
}
.markdown-body h1,
.markdown-body h2 {
  color: rgba(0, 0, 0, 0.9);
  font-weight: 400;
}
.markdown-body blockquote {
  margin: 0;
  padding: 10px 0;
  border-left: 4px solid #6eacfd;
  font-size: 16px;
  color: #999;
  background: #f8fafd;
  box-sizing: border-box;
}
 
.markdown-body blockquote > * {
  margin: 0 18px;
  margin-top: 0px;
  margin-right: 18px;
  margin-bottom: 0px;
  margin-left: 18px;
}
 
.markdown-body .tab-container,
.markdown-body .tab-item-container {
  background-color: #eaf1f9;
  overflow-y: hidden;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.markdown-body .tab-item-container {
  display: flex;
  align-items: center;
  list-style: none;
  padding-left: 0;
  padding-right: 0;
  height: 48px;
  margin: 0 20px;
}
.markdown-body .singlePreContainer {
  position: relative;
}
 
.markdown-body .hljs-attr,
.markdown-body .hljs-number,
.markdown-body .hljs-selector-attr,
.markdown-body .hljs-selector-class,
.markdown-body .hljs-selector-pseudo,
.markdown-body .hljs-template-variable,
.markdown-body .hljs-type,
.markdown-body .hljs-variable {
  color: #d19a66;
}
.jsonStyle {
  background-color: #f8f8f9;
}
.title {
  font-size: 18px;
  font-weight: 600;
}
 
/* ================= */
.my-editor {
  /* we dont use `language-` classes anymore so thats why we need to add background and text color manually */
  background: #2d2d2d;
  color: #ccc;
 
  /* you must provide font-family font-size line-height. Example: */
  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
  padding: 5px;
  width: 90%;
  margin: 0 auto;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
 
/* optional class for removing the outline */
.prism-editor__textarea:focus {
  outline: none;
}
 
/* not required: */
.height-300 {
  height: 300px;
}

.myicon{
    cursor: pointer;
}
</style>