<template>
  <div>
    <h2 style="text-align: center;">问题反馈</h2>
    <textarea name="" id="" placeholder="请输入您的反馈内容" class="feedback-textarea" v-model="content"></textarea>
    <input type="text" placeholder="请输入您的联系方式方便后续沟通" class="feedback-input" v-model="phone">
    <div class="feedback-btn">
      <el-button type="primary" @click="submitFeedback">提交</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      content: '',
      phone: '',
    }
  },
  methods: {
    submitFeedback(){
      if(this.content.trim() === '' || this.phone.trim() === ''){
        this.$message.error('请填写反馈内容和联系方式');
        return;
      }else if(!/^1[3-9]\d{9}$/.test(this.phone)){
        this.$message.error('请输入正确的手机号码');
        return;
      }
      else{
        this.$message.success('反馈成功，我们会尽快处理');
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.feedback-textarea{
  width: 60%;
  height: 300px;
  display: block;
  margin: 20px auto;
  font-size: 20px;
  resize: none;
  outline: none;
}

.feedback-input{
  width: 60%;
  margin: 20px auto;
  display: block;
  height: 40px;
  font-size: 20px;
  // 选中后边框不变粗
  outline: none;
}

.feedback-btn{
  margin: 20px auto;
  text-align: center;
  .el-button{
    width: 150px;
    font-size: 20px;
  }
}

</style>