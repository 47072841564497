<template>
  <div class="about">
    <h3>编辑项目</h3>
    <div class="content">
        <div class="code_content">
            <el-input
            type="textarea"
            :rows="10"
            placeholder="代码编辑"
            v-model="task_obj.code">
            </el-input>
        </div>
        <div class="task_name">
            <el-input v-model="task_obj.task_name" placeholder="任务名" disabled></el-input>
        </div>
        <div class="button">
            <el-button type="primary" @click="updateTask" class="create">确认修改</el-button>
            
        </div>
    </div>
  </div>
</template>


<script>
import axios from 'axios';
// @ is an alias to /src


export default {
  name: 'HomeView',
  components: {


  },
  data(){
    return{

        task_obj:this.$route.query.item
    }
  },
  mounted(){

  },
  methods:{
    updateTask(){
      axios.post('/task/update_task',this.task_obj,
      {headers:{token:localStorage.getItem('token')||''}}).then((resp) => {
        console.log(resp)
        if(resp.data.status == 200){
          this.$message({
            message: '修改成功!',
            type: 'success'
          });
          this.$router.push('/home/subview3')
        }else{
          this.$message.error("修改失败!")
        }
      }).catch((err) => {
        console.log(err)
      });
    },
  }

}
</script>

<style scoped>
.button{
  margin: 0 auto;
  width: 200px;
}
.upload{
  float: left;
}
.create{
  float: left;
}
.content{
  box-shadow: 0 2px 4px rgba(0, 0, 0, .4), 0 0 6px rgba(0, 0, 0, .1);
  margin: 10px;
  padding: 5px;
  width: 1000px;
  height: 500px;
}
.nav{
  width: 200px;
}
.code_content{
    width: 80%;
    margin: 10px auto;
}
.task_name{
    width: 80%;
    margin: 10px auto;
}


</style>

