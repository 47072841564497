<template>
  <div class="main">
    <div class="head">
    </div>
    <div class="left" :style="{width: left_width}">
      <div class="retract">
        <i class="el-icon-s-fold" circle @click="retract"></i>
      </div>
      <div  v-if="$store.state.user.username">
        <div class="title">
          <img :src="$store.state.user.avatar" alt="" class="avatar">
          <span  v-if="left_width=='15%'">{{$store.state.user.username}}</span>
        </div>
        <div class="btn_dlg" v-if="left_width=='15%'">
          <el-button class="btn_item" type="primary" @click="gotoUserCenter(2)">我的收藏</el-button>
        </div>
        <div class="btn_dlg"  v-if="left_width=='15%'">
          <el-button class="btn_item"  @click="gotoUserCenter(3)"> 
            历史记录
          </el-button>
        </div>
      </div>
      <div v-else class="unlogin">
        <el-button @click="showLoginForm">请登录</el-button>
      </div>
      <!-- <div class="windows">
        <el-collapse v-model="activeNames" @change="handleChange" class="windows_show">
          <el-collapse-item title="全部窗口" name="1">
            <div v-if="ai_windows.length==0">暂无数据</div>
            <div v-else>窗口展示</div>
          </el-collapse-item>
        </el-collapse>
      </div> -->

      <div class="upgrade"  v-if="left_width=='15%'">
        <div class="up_title">
          更多内容
        </div>
        <div class="up_content">
          感谢您使用我们的网站！非常欢迎您加入我们的大家庭，与我们一起成长，共同进步。
          期待通过我们的平台，帮助您进一步提升科研能力，更快的发表文章。
        </div>
        <div>
          <el-button class="up_btn" @click="gotoVip">点击加群</el-button>
        </div>
      </div>

      <!-- <div class="issue"  v-if="left_width=='15%'">
        问题反馈
      </div> -->
    </div>
    <div class="right" :style="{width: right_width}">
      <div class="discounts">
        <div>
          <img src="/static/logo/白天版主logo.png" alt="" class="logo-img">
        </div>
      </div>
      <div class="show_content">
        全国首个专注生物信息学垂直领域的AI搜索引擎
      </div>
      <div class="functions">
        <!-- <el-button-group>
          <el-button style="width:100px">简洁</el-button>
          <el-button style="width:100px">增强</el-button>
          <el-button style="width:100px">研究</el-button>
        </el-button-group> -->

        <div class="func_item"><div @click="mode=0" :style="{background:mode_color[0]}">简洁搜索</div></div>
        <div class="func_item"><div @click="mode=1" :style="{background:mode_color[1]}">增强模式</div></div>
        <!-- <div class="func_item"><div @click="mode=2" :style="{background:mode_color[2]}">研究</div></div> -->
        <div class="clear"></div>
      </div>

      <!-- <div class="anwser1">
        BioCode Hub
      </div> -->
      <!-- <div class="anwser2">
        AI智能搜索结果...
      </div> -->
      <!-- <div class="mode">
        <el-button-group>
          <el-button type="primary">普通搜索</el-button>
          <el-button type="primary">高级搜索</el-button>
        </el-button-group>
      </div> -->
      <div class="question">
        
        <div  class="question_input">
          <textarea name="" id="" rows="3" placeholder="请输入内容" v-model="question"></textarea>
        </div>
        
        <div class="question_btn">
          <el-button icon="el-icon-s-promotion" type="primary" @click="gotoPage(1)">搜索</el-button>
        </div>

        <div class="clear"></div>
      </div>
        <div class="tip">
          本网站提供的搜索结果及其包含的网站链接均由人工智能生成，可能存在信息不准确或不完整的情况。请用户谨慎使用搜索结果，并在<span style="font-weight:bold">点击生成的网站链接前务必谨慎</span>核实相关信息。本网站对因依赖AI生成内容而产生的任何损失或责任概不负责。
       </div>
    </div>
    <div class="clear"></div>

  </div>
</template>

<script>
import axios from 'axios';
export default {
    data() {
      var checkUsername = (rule, value, callback) => {
          if (!value) {
              return callback(new Error('账号不能为空'));
          }else{
              callback();
          }
      };
      var checkpassword = (rule, value, callback) => {
          if (!value) {
              return callback(new Error('密码不能为空'));
          }else{
              callback();
          }
      };
      return {
        user:{
            username:'',
            password:'',
        },
        rules: {
            username: [
                { validator: checkUsername, trigger: 'blur' }
            ],
            password: [
                { validator: checkpassword, trigger: 'blur' }
            ]
        },
        activeNames: ['1'],
        ai_windows:[],
        question:'',
        dialogVisible: false,
        left_width:'15%',
        right_width:'80%',
        mode:0, //0简洁 1增强 2研究
        mode_color:['#dae9ff', '#ffffff', '#ffffff'],
      };
    },
    created() {
      // 获取get参数
      let params = this.$route.query
      console.log(params)
      let user_id = params.user_id
      let open_id = params.open_id
      if(user_id && open_id){
        axios.get('/user/wxinfo/',{params:{user_id:user_id, open_id:open_id}})
        .then((resp)=>{
          console.log(resp)
          if(resp.data.code==200){
              this.$message({
                  message: '登录成功',
                  type: 'success'
              });
              let user_str = JSON.stringify(resp.data.user)
              localStorage.setItem('user', user_str)
              localStorage.setItem('token', resp.data.token)
              this.$store.commit('setUser', resp.data.user)
              this.$router.push('/')
          }else{
              this.$message.error(resp.data.msg)
          }
          
      })
      .catch((err)=>{
          console.log('network submit!!');
          console.log(err);
          
      })

      }
    },

    watch: {
      mode:function(v){
        console.log(v);
        if(v==0){
          this.mode_color = ['#dae9ff', '#ffffff', '#ffffff']
        }else if(v==1){
          this.mode_color = ['#ffffff', '#dae9ff', '#ffffff']
          if(!this.$store.state.user.username){
            this.$message.error({
                message: '用户未登录',
            });
            // this.$store.commit('setLoginFlag', true)
            this.mode = 0
          }else if(!this.$store.state.user.vip){
            this.$message.error({
                message: '当前用户不是会员优惠，无法使用增强搜索',
            })
            this.mode = 0
          }
        }else{
          this.mode_color = ['#ffffff', '#ffffff', '#dae9ff']
        }
      }
    },
    methods: {
      addGroup(){
        this.$router.push('/about')
      },
      showLoginForm(){
        this.$store.commit('setLoginFlag', true)
        // this.visible=false
      },
      gotoUserCenter(menu){
        this.$router.push({name:'UserCenter', params:{menu: menu}})
      },
      retract(){
        if(this.left_width=='15%'){
          this.left_width='8%'
          this.right_width='90%'
        }else{
          this.left_width='15%'
          this.right_width='80%'
        }
        console.log(this.left_width)
      },
      gotoVip(){
        this.$router.push('/vip')
      },
      showLoginForm(){
        this.$store.commit('setLoginFlag', true)
        this.visible=false
      },
      gotoPage(i){
        if(!this.$store.state.user.username){
          this.$message({
              message: '用户未登录',
              type: 'error'
          });
          return
        }

        let path_name = 'Page' + i
        this.$router.push({name:path_name, params:{question:this.question, mode:this.mode}})
      },
       handleChange(val) {
        console.log(val);
      },
      handleClose(done) {
        this.$confirm('确认关闭？')
          .then(_ => {
            done();
          })
          .catch(_ => {});
      },
      login(formName) {
          // this.$refs[formName].validate((valid) => {
          if (1) {
              Axios.post('/user/login',this.user)
              .then((resp)=>{
                  console.log(resp)
                  if(resp.data.status==200){
                      this.$message({
                          message: '登录成功',
                          type: 'success'
                      });
                      this.$router.push('/')
                      let user_str = JSON.stringify(resp.data.user)
                      localStorage.setItem('user', user_str)
                      localStorage.setItem('token', resp.data.token)
                  }else{
                      this.$message.error(resp.data.msg)
                  }
                  
              })
              .catch((err)=>{
                  console.log('network submit!!');
                  console.log(err);
                  
              })
              this.dialogVisible = false
          } else {
              console.log('error submit!!');
              return false;
          }
          // });
      },
    }
  }
</script>

<style lang="scss" scoped>
.main{
  height: 800px;
  text-align: center;
}
.head{
  height: 10px;
}
.left{
  height: 880px;
  border-right: 1px solid rgb(216, 215, 215);
  float: left;
  @include background_color('background_color2')
}
.right{
  // width: 80%;
  float: left;
  height: 800px;
}
.clear{
  clear: both;
}
.title{
  font-size: 20px;
  text-align: center;
  margin: 10px;
  height: 50px;
  span{
    position: relative;
    bottom: 10px;
    left: 10px;
  }
}
.btn_dlg{
  text-align: center;
  padding: 5px;
  .btn_item{
    width: 90%;
  }
}
.windows{
  .windows_show{
    width: 90%;
    margin: 0 auto;
  }
}

.upgrade{
  width: 90%;
  margin: 10px auto;
  margin-top: 150px;
  border: 1px solid rgb(216, 215, 215);
  height: 330px;
  border-radius: 20px;
  .up_title{
    font-weight: bold;
    font-size: 20px;
    margin: 10px;
  }
  .up_content{
    margin: 10px;
    text-align: left;
    text-indent: 2em;
  }
  .up_btn{
    margin: 10px auto;
    width:90%;
  }
}

.issue{
  font-size: 10px;
}

.discounts{
  margin: 10px;
  padding: 10px;
  font-size: 18px;
  line-height: 25px;
  // border-bottom:1px solid rgb(216, 215, 215);
}

.show_content{
  font-size: 25px;
  font-weight: bolder;
}

.anwser1{
  height: 30px;
  width: 80%;
  margin: 0 auto;
  border-bottom: 1px solid white;
  // border-radius: 20px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background: #3d3d3d;
  margin-top:20px ;
  color: white;
  line-height: 30px;
  text-align: left;
  text-indent: 2em;

}
.anwser2{
  height: 300px;
  width: 80%;
  margin: 0 auto;
  border: 2px solid black;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  background: #09090b;
  margin-bottom:20px ;
  @include font_color('font_color2');
  text-align: left;
  text-indent: 4em;
  line-height: 30px;
}

.question{
  height: 100px;
  width: 60%;
  margin: 10px auto;
  // border: 1px solid grey;
  border-radius: 20px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1)
}

.question_input{
  width: 70%;
  padding: 10px 20px;
  float: left; 
  textarea{
    width: 100%;
  }
}
.question_btn{
  display: inline-block;
  position: relative;
  // float: left;
  width: 15%;
  line-height: 100px;
}

.login_input{
  width: 350px;
}

.functions{
  width: 400px;
  margin: 10px auto;
  padding: 20px;
}
.func_item{
  float: left;
  width: 50%;
  height: 50px;
  div{
    width: 180px;
    margin: 5px auto;
    height: 30px;
    border-radius: 10px;
    line-height: 30px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    font-weight: bold;
    cursor: pointer;
    color: black;
    // background-color: #3d3d3d;
    // @include background_color('background_color2')
  }
}

.el-button{
  @include background_color('button_background_color1');
  @include font_color('button_color1');
  @include border_color('button_border_color1');
}

.avatar{
  width: 50px;
  height: 50px;
  border-radius: 25px;
}

.retract{
  cursor: pointer;
  position: absolute;
  left: 30px;
  top: 130px;
}
.retract:hover{
  color:grey;
}

textarea{
  font-size: 20px;
  border: none;
  outline: none;
  resize: none;
  border-radius: 10px;
  padding: 5px;
}

.unlogin{
  height: 50px;
  line-height: 50px;
  font-size: 20px;
}

.logo-img{
  width: 98px;
  height: 109px;
}
.add_group{
  text-align:center;
  padding:5px;
  font-size:13px;
  cursor: pointer;
}

.tip{
  width: 50%;
  text-align: left;
  text-indent: 2em;
  margin: 10px auto;
  font-size: 13px;
  color: gray;
}
</style>