<template>
  <div>
    <div class="tit1">
        个人中心
        &emsp;
        <!-- <el-button type="success">修改密码</el-button> -->
    </div>
    <el-form ref="form" :model="userInfo" label-width="80px" class="userform" :rules="rules">
        <el-form-item label="用户名:">
            <el-input v-model="userInfo.username" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="手机:" prop="mobile">
            <el-input v-model="userInfo.mobile" :disabled="editFlag"></el-input>
        </el-form-item>
        <el-form-item label="邮箱:">
            <el-input v-model="userInfo.email" :disabled="editFlag"></el-input>
        </el-form-item>
        <el-form-item>
            <el-button type="primary" @click="editForm">编辑</el-button>
            <el-button type="success" @click="onSubmit" v-if="editFlag==false">提交</el-button>
        </el-form-item>
    </el-form>
  </div>
</template>

<script>
import axios from 'axios'
export default {
    data(){
        var validateMobile = (rule, value, callback) => {
            if (!value) {
            return callback(new Error('手机号不能为空'));
            }
            let r = /^1[3-9]\d{9}$/
            if(!r.test(value)){
                return callback(new Error('手机号格式不正确'));
            }

            return callback()
        };
        return{
            editFlag:true,//true不可编辑 false可编辑
            userInfo:{},
            rules: {
                mobile: [
                    { validator: validateMobile, trigger: 'blur' }
                ],
            }
        }
    },
    created(){
        this.getUserInfo();
    },
    methods:{
        editForm(){
            this.editFlag = false;//可编辑
        },
        onSubmit(){
            axios.post('/user/me',this.userInfo,  {headers:{token:localStorage.getItem('token')||""}})
            .then(resp=>{
                if(resp.data.code == 200){
                    this.editFlag = true;//不可编辑
                    this.$notify({
                        title: '成功',
                        message: '修改个人信息成功!',
                        type: 'success'
                    });
                    console.log(this.userInfo)
                    localStorage.setItem("user", JSON.stringify(this.userinfo));
                    this.$store.commit("setUser", this.userInfo);
                }else{
                    alert(resp.data.msg);
                }
                this.getUserInfo();//获取最新信息
            })
            .catch(err=>{
                console.log(err)
            })
        },
        getUserInfo(){
            axios.get('/user/me', {headers:{token:localStorage.getItem('token')||''}})
            .then(resp=>{
                if(resp.data.code == 200){
                    this.userInfo = resp.data.info;
                }else{
                    alert(resp.data.msg);
                }
            })
            .catch(err=>{
                console.log(err)
            })
        }
    },

}
</script>

<style>
.tit1{
    color: cornflowerblue;
    font-size: 30px;
    width: 300px;
    /* height: 100px; */
    padding: 50px;
}

.userform{
    width: 400px;
    padding-left: 50px;
    font-weight: bolder;
}
</style>