import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Vip from '../views/VipView.vue'
import Index from '../views/IndexView.vue'
import Login from '../views/Login.vue'
import Visual from '../views/VisualView.vue'
import VisualDetail from '../views/VisualDetail.vue'
import Register from '../views/Register.vue'    
import Page1 from '../views/PageView1.vue'    
import Pay from '../views/PayView.vue'    
import UserCenter from '../views/UserCenter.vue'    
import AgreementView from '../views/AgreementView.vue'    
import Test from '../views/TestView.vue'    
import WxPay from '../views/WxPay.vue'    
import MenuView01 from '../views/MenuView01.vue'
import MenuView02 from '../views/MenuView02.vue'
import MenuView03 from '../views/MenuView03.vue'
import MenuView04 from '../views/MenuView04.vue'
import MenuView05 from '../views/MenuView05.vue'
import MenuView06 from '../views/MenuView06.vue'
import MenuView07 from '../views/MenuView07.vue'
import MenuView08 from '../views/MenuView08.vue'
import MenuView09 from '../views/MenuView09.vue'
import MenuView10 from '../views/MenuView10.vue'
Vue.use(VueRouter)

// 获取原型对象push函数
const originalPush = VueRouter.prototype.push
 
// 获取原型对象replace函数
const originalReplace = VueRouter.prototype.replace
 
// 修改原型对象中的push函数
VueRouter.prototype.push = function push(location){
return originalPush.call(this , location).catch(err=>err)
}
 
// 修改原型对象中的replace函数
VueRouter.prototype.replace = function replace(location){
return originalReplace.call(this , location).catch(err=>err)
}

const routes = [
  {
    path: '/',
    name: 'Index',
    component: Index
  },
  {
    path: '/wxpay',
    name: 'WxPay',
    component: WxPay
  },
  {
    path: '/agree',
    name: 'Agree',
    component: AgreementView
  },
  {
    path: '/usercenter',
    name: 'UserCenter',
    component: UserCenter
  },
  {
    path: '/pay',
    name: 'Pay',
    component: Pay
  },
  {
    path: '/test',
    name: 'Test',
    component: Test
  },
  {
    path: '/visual',
    name: 'Visual',
    component: Visual
  },
  {
    path: '/visualDetail',
    name: 'VisualDetail',
    component: VisualDetail
  },
  {
    path: '/vip',
    name: 'Vip',
    component: Vip
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/register',
    name: 'Register',
    component: Register
  },
  {
    path: '/page1',
    name: 'Page1',
    component: Page1
  },
  {
    path: '/home',
    name: 'home',
    component: HomeView,
    children:[
      {
        path: 'subview1',
        name: 'subview1',
        component: MenuView01
      },
      {
        path: 'subview2',
        name: 'subview2',
        component: MenuView02
      },
      {
        path: 'subview3',
        name: 'subview3',
        component: MenuView03
      },
      {
        path: 'subview4',
        name: 'subview4',
        component: MenuView04
      },
      {
        path: 'subview5',
        name: 'subview5',
        component: MenuView05
      },
      {
        path: 'subview6',
        name: 'subview6',
        component: MenuView06
      },
      {
        path: 'subview7',
        name: 'subview7',
        component: MenuView07
      },
      {
        path: 'subview8',
        name: 'subview8',
        component: MenuView08
      },
      {
        path: 'subview9',
        name: 'subview9',
        component: MenuView09
      },
      {
        path: 'subview10',
        name: 'subview10',
        component: MenuView10
      },
    ]
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
