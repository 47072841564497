<template>
  <div>
    <h1>定时任务</h1>
    <div>
      <el-button type="success" @click="dialogVisible = true">添加定时任务</el-button>
    </div>

    <div>
      <el-dialog
        title="添加定时任务"
        :visible.sync="dialogVisible"
        width="30%"
        :before-close="handleClose">
         
        <el-form ref="form" :model="new_task" label-width="80px">
          <el-form-item label="任务">
            <el-select v-model="new_task.task_id" placeholder="请选择要执行的任务">
              <el-option :label="item.task_name" :value="item.id" v-for="(item, i) in tasks_list" :key="i"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="节点">
            <el-select v-model="new_task.node_id" placeholder="请选择要运行节点">
              <el-option :label="item.ip_addr" :value="item.id" v-for="(item, i) in node_list" :key="i"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="执行方式">
            <el-select v-model="new_task.flag" placeholder="请选择执行方式">
              <el-option label="定时执行一次" :value="0"></el-option>
              <el-option label="间隔循环执行" :value="1"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="间隔(秒)" v-if="new_task.flag">
            <el-input v-model="new_task.interval" placeholder="多长时间后执行" style="width:220px"></el-input>
          </el-form-item>
          <el-form-item label="运行时间">
            <el-date-picker
              v-model="new_task.run_time"
              type="datetime"
              placeholder="选择日期时间">
            </el-date-picker>
          </el-form-item>
          
        </el-form>




        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="addTimed">确 定</el-button>
        </span>
      </el-dialog>
    </div>

    <el-table
      :data="timed_list"
      style="width: 900px; text-align:center; margin:0 auto;">
      <el-table-column
        label="编号"
        width="100">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.row.id }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="节点IP"
        width="200">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.row.ip }}</span>
        </template>
      </el-table-column>
       <el-table-column
        label="是否启用"
        width="100">
        <template  slot-scope="scope">
          <span style="margin-left: 10px" v-if="scope.row.status==1"><el-tag type="success">启用</el-tag></span>
          <span style="margin-left: 10px" v-if="scope.row.status==0"><el-tag type="danger">停止</el-tag></span>
        </template>
      </el-table-column>
      <el-table-column
        label="定时方式"
        width="100">
        <template  slot-scope="scope">
          <span style="margin-left: 10px" v-if="scope.row.flag==1"><el-tag type="success">间隔</el-tag></span>
          <span style="margin-left: 10px" v-if="scope.row.flag==0"><el-tag>定时</el-tag></span>
        </template>
      </el-table-column>
      <el-table-column
        label="执行时间"
        width="200">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.row.run_time }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="200px">
        <template slot-scope="scope">
          
          <el-button
            size="mini"
            type="success"
            v-if="scope.row.status==0"
            @click="handleStart(scope.$index, scope.row)">启用</el-button>
            <el-button
            size="mini"
            type="warning"
            v-if="scope.row.status==1"
            @click="handleStop(scope.$index, scope.row)">停止</el-button>
            <el-button
            size="mini"
            type="danger"
            @click="handleDelete(scope.$index, scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import axios from 'axios';
// @ is an alias to /src

export default {
  name: 'HomeView',
  components: {


  },
  data(){
    return{
        dialogVisible: false,
        timed_list:[],
        node_list:[],
        tasks_list:[],
        new_task:{
          task_id:'',
          node_id:'',
          flag:'',
          interval:'',
          run_time:'',
        },
    }
  },
  mounted(){
    this.allTimed()
    this.allNode()
    this.getAllTasks()

  },
  watch:{

  },
  methods:{
    getAllTasks(){
      axios.get('/task/all_task', {headers:{token:localStorage.getItem('token')||''}})
      .then((result) => {
        console.log(result)
        this.tasks_list = result.data.data

      }).catch((err) => {
        console.log(err)
      });
    },
    allNode() {
      axios.get('/task/all_node',
      {headers:{token:localStorage.getItem('token')||''}}).then((resp) => {
        console.log(resp)
        this.node_list = resp.data.data
      }).catch((err) => {
        console.log(err)
      });
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          done();
        })
        .catch(_ => {});
    },
    handleDelete(i, row){
      axios.post('/task/delete_timed', {timed_id: row.id}, 
      {headers:{token:localStorage.getItem('token')||''}}).then((resp) => {
        console.log(resp)
        this.$message({
            message: '删除定时任务!',
            type: 'success'
          });
          this.allTimed()
      }).catch((err) => {
        console.log(err)
      });
    },
     handleStop(i, row){
        axios.post('/task/stop_timed', {timed_id:row.id}, 
        {headers:{token:localStorage.getItem('token')||''}}).then((resp) => {
          console.log(resp)
          if(resp.data.status==200){
            this.$message({
              message: '停止定时任务成功!',
              type: 'success'
            });
          }else if(resp.data.status==201){
            this.$message.error("程序已停止")
          }else{
            this.$message.error("运行失败")
          }
          this.allTimed()
        }).catch((err) => {
          console.log(err)
        });
      },
    handleStart(i, row){
        axios.post('/task/run_timed', {timed_id:row.id}, 
        {headers:{token:localStorage.getItem('token')||''}}).then((resp) => {
          console.log(resp)
          if(resp.data.status==200){
            this.$message({
              message: '运行定时任务成功!',
              type: 'success'
            });
          }else if(resp.data.status==201){
            this.$message.error("程序已运行")
          }else{
            this.$message.error("运行失败")
          }
          this.allTimed()
        }).catch((err) => {
          console.log(err)
        });
      },
    allTimed() {
      axios.get('/task/all_timed',
      {headers:{token:localStorage.getItem('token')||''}}).then((resp) => {
        console.log(resp)
        this.timed_list = resp.data.data
      }).catch((err) => {
        console.log(err)
      });
    },
    addTimed(){
      this.dialogVisible = false
      console.log(this.new_task)
      if(this.new_task.flag==0){
        this.new_task.interval = 0
      }else{
        this.new_task.interval = parseInt(this.new_task.interval)
      }
      axios.post('/task/create_timed', this.new_task, 
      {headers:{token:localStorage.getItem('token')||''}}).then((resp) => {
        console.log(resp)
        if(resp.data.status==200){
          this.$message({
            message: '新建定时任务成功!',
            type: 'success'
          });
          this.allTimed()
        }else if(resp.data.status == 401){
          this.$message.error("定时日期小于当前时间")
        }
      }).catch((err) => {
        console.log(err)
      });
    }
  }

}
</script>

<style scoped>

</style>