<template>
  <div>
    <h3>应用列表</h3>
    <!-- {{tasks_list}} -->
      <el-table
      :data="tasks_list"
      style="width: 100%; text-align:center;">
      <el-table-column
        label="编号"
        width="100">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.row.id }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="任务名"
        width="100">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.row.task_name }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="运行次数"
        width="100">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.row.run_count }}</span>
        </template>
      </el-table-column>
       <el-table-column
        label="错误次数"
        width="100">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.row.err_count }}</span>
        </template>
      </el-table-column>
       <el-table-column
        label="运行结果"
        width="100">
        <template slot-scope="scope">
          <span style="margin-left: 10px" v-if="scope.row.status==0"><el-tag>待运行</el-tag></span>
          <span style="margin-left: 10px" v-if="scope.row.status==1"><el-tag type="success">成功</el-tag></span>
          <span style="margin-left: 10px" v-if="scope.row.status==2"><el-tag type="danger">失败</el-tag></span>
        </template>
      </el-table-column>
      <el-table-column
        label="创建时间"
        width="200">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.row.create_time }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="primary"
            @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
          <el-button
            size="mini"
            type="danger"
            @click="handleDelete(scope.$index, scope.row)">删除</el-button>
          <el-button
            size="mini"
            type="success"
            @click="handleRun(scope.$index, scope.row)">启动</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  data(){
    return{
      tasks_list:[],
    }
  },
  methods:{
    getAllTasks(){
      axios.get('/task/all_task', {headers:{token:localStorage.getItem('token')||''}})
      .then((result) => {
        console.log(result)
        this.tasks_list = result.data.data

      }).catch((err) => {
        console.log(err)
      });
    },
    handleDelete(i, item){
      axios.post('/task/delete_task', {task_id: item.id},{headers:{token:localStorage.getItem('token')||''}})
      .then((result) => {
        console.log(result)
        this.getAllTasks()
      }).catch((err) => {
        console.log(err)
      });
    },
    handleEdit(i, item){
      this.$router.push({path:'/home/subview10', query:{item: item} })
    },
    handleRun(i, item){
      axios.post('/task//run_task', {task_id:item.id}, {headers:{token:localStorage.getItem('token')||''}})
      .then((result) => {
        console.log(result)
        this.$message({
                  message: '程序已运行',
                  type: 'success'
              });
        this.getAllTasks()
      }).catch((err) => {
        console.log(err)
      })
    }

  },
  mounted(){
    this.getAllTasks()
  }
}
</script>

<style>

</style>
