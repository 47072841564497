<template>
    <el-dialog
    :visible.sync="flag"
    width="500px"
    center>
    <div class="reg">
        <h2>注册新用户</h2> <br>
        <el-form :model="reg_user" status-icon :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm"
        style="width:400px; margin:0 auto;">
            <el-form-item label="账号:" prop="username">
                <el-input v-model="reg_user.username" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="密码:" prop="password">
                <el-input type="password" v-model="reg_user.password" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="手机:" prop="mobile">
                <el-input v-model="reg_user.mobile" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="验证码:" prop="code" style="text-align:left;">
                <el-input v-model="reg_user.code" autocomplete="off" style="width:50%"></el-input>
                <img :src="url" alt="" @click="imgCodeUrl" class="imgcode">
            </el-form-item>
            <el-form-item>
                <el-button @click="flag=false">取 消</el-button>
                <el-button type="primary" @click="Register('ruleForm')">登录</el-button>
            </el-form-item>
        </el-form>
    </div>
    </el-dialog>
</template>

<script>
import {mapState,mapMutations} from 'vuex'
import {v4 as uuid4} from 'uuid'
import axios from 'axios';
export default {
    data(){
        var validateUsername = (rule, value, callback) => {
            if (!value) {
            return callback(new Error('用户名不能为空'));
            }
            let usernameRule = /^[a-zA-Z]\w{4,14}$/
            if(!usernameRule.test(value)){
                return callback(new Error('用户名必须以字母开头,由5-15位字母数字组成'));
            }
            return callback()
        };
        var validatePassword = (rule, value, callback) => {
            if (!value) {
            return callback(new Error('密码不能为空'));
            }
            let r = /^\w{5,15}$/
            if(!r.test(value)){
                return callback(new Error('密码由5-15位字母数字组成'));
            }

            return callback()
        };
        var validateMobile = (rule, value, callback) => {
            if (!value) {
            return callback(new Error('手机号不能为空'));
            }
            let r = /^1[3-9]\d{9}$/
            if(!r.test(value)){
                return callback(new Error('手机号格式不正确'));
            }

            return callback()
        };
        var validateCode = (rule, value, callback) => {
            if (!value) {
            return callback(new Error('验证码不能为空'));
            }
            let r = /^\w{4}$/
            if(!r.test(value)){
                return callback(new Error('验证码格式不正确'));
            }

            axios.post('/user/code/'+this.uuid, {code:this.reg_user.code})
            .then(resp=>{
                console.log(resp)
                if(resp.data.code == 200){
                    // this.$message({message: resp.data.msg,type: 'success'});
                    callback()
                }else{
                    // this.$message.error(resp.data.msg);
                    callback(new Error(resp.data.msg));
                }
            })
            .catch(err=>{
                console.log(err)
            })
            
        };
        return{
            uuid:'',
            url:'',
            flag:false,
            reg_user: {
                username: '',
                password: '',
                mobile:'',
                code:'',
            },
            rules: {
                username: [
                    { validator: validateUsername, trigger: 'blur' }
                ],
                password: [
                    { validator: validatePassword, trigger: 'blur' }
                ],
                mobile: [
                    { validator: validateMobile, trigger: 'blur' }
                ],
                code: [
                    { validator: validateCode, trigger: 'blur' }
                ],
            }
        }
    },
    created(){
        // this.flag = this.login_flag;
        this.imgCodeUrl();
    },
    computed:{
        ...mapState(['reg_flag']),//映射登录标记位
    },
    methods:{
        imgCodeUrl(){
            this.uuid = uuid4();
            this.url = '/user/code/'+this.uuid
        },
        Register(formName) {
            this.$refs[formName].validate((valid) => {
            if (valid) {
                axios.post("user/register", this.reg_user)
                .then(resp=>{
                    console.log(resp)
                    // if(resp.data.code == 200){
                    //     this.$message({message: resp.data.msg,type: 'success'});
                    //     let userinfo = resp.data.user
                    //     let token = resp.data.token
                    //     localStorage.setItem('user', JSON.stringify(userinfo))
                    //     localStorage.setItem('token', token)
                    //     this.$store.commit('setUser', userinfo)
                    //     // this.$router.push('/welcome')
                    // }else{
                    //     this.$message.error(resp.data.msg);
                    // }
                })
                .catch(err=>{
                    console.log(err)
                })
            } else {
                console.log('error submit!!');
                return false;
            }
            });
        },
        ...mapMutations(['setRegFlag']),
        
    },
    watch:{
        reg_flag: function(v){
            this.flag = v;
        },
        flag:function(v){
            console.log(v);
            this.setRegFlag(v);
        }
    }
}
</script>

<style>
.reg{
    text-align: center;
}
.imgcode{
    width: 45%;
    position: absolute;
}
</style>