<template>
  <div >
    <div style="height: 100px; line-height: 100px;">
      <h2>可视化代码展示</h2>
    </div>
    <div class="visual-show">
      <div class="visual-container" v-for="(item, index) in visual_data" :key="index" @click="gotoDetail(item.id)">
        <div class="visual-img">
          <img :src="item.picture" alt="">
        </div>
        <div class="visual-name">
          {{item.name}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  data(){
    return {
      visual_data: [
      ]
    }
  },
  mounted(){
    this.getVisualData()
  },
  methods: {
    gotoDetail(id){
      this.$router.push({name: 'VisualDetail', params: {id: id}})
    },
    getVisualData(){
      // 获取可视化数据
      axios.get('/ai/visual/').then(res => {
        console.log(res)
        this.visual_data = res.data.data
      }).catch(err => {
        console.log(err)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.visual-show{
  width: 80%;
  padding: 20px;
  margin: 20px auto;
  // 弹性布局
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  cursor: pointer;
  .visual-container{
    width: 25%;
    height: 260px;
    margin-bottom: 20px;
    img{
      width: 200px;
      /* 高度与宽度相等 */
      height: 200px;
    }
  }
  .visual-name{
    font-size: 20px;
  }
  .visual-name:hover{
    font-weight: bold;
  }
}
</style>