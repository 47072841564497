<template>
  <div>
    <h1>监控管理</h1>
    <div>
      <el-select v-model="status" placeholder="状态选择">
        <el-option
          :key="2"
          label="全部"
          :value="2">
        </el-option>
        <el-option
          :key="1"
          label="成功"
          :value="1">
        </el-option>
        <el-option
          :key="0"
          label="失败"
          :value="0">
        </el-option>
      </el-select>
    </div>

    <el-table
      :data="msg_list"
      style="width: 700px; text-align:center; margin:0 auto;">
      <el-table-column
        label="编号"
        width="100">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.row.id }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="任务名"
        width="100">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.row.task_name }}</span>
        </template>
      </el-table-column>
       <el-table-column
        label="运行结果"
        width="100">
        <template  slot-scope="scope">
          <span style="margin-left: 10px" v-if="scope.row.status==1"><el-tag type="success">成功</el-tag></span>
          <span style="margin-left: 10px" v-if="scope.row.status==0"><el-tag type="danger">失败</el-tag></span>
        </template>
      </el-table-column>
      <el-table-column
        label="运行时间"
        width="200">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.row.create_time }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="primary"
            @click="handleEdit(scope.$index, scope.row)">详情</el-button>
          <el-button
            size="mini"
            type="danger"
            @click="handleDelete(scope.$index, scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div>
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page.sync="c_page"
        :page-size="10"
        layout="total, prev, pager, next"
        :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
// @ is an alias to /src

export default {
  name: 'HomeView',
  components: {


  },
  data(){
    return{
        msg_list:[],
        c_page:1,
        total:0,
        status:2,
    }
  },
  mounted(){
    if(this.$route.query.status==0){
      this.status = 0
    }

    this.allMsg()
  },
  watch:{
    status:function(new_value, old_value){
      this.allMsg()
    }
  },
  methods:{
    handleDelete(i, row){
      axios.post('/task/delete_msg', {msg_id: row.id}, 
      {headers:{token:localStorage.getItem('token')||''}}).then((resp) => {
        console.log(resp)
        this.$message({
            message: '删除成功',
            type: 'success'
          });
          this.allMsg()
      }).catch((err) => {
        console.log(err)
      });
    },
    handleEdit(index, row){
      this.$alert(row.msg, '详情', {
          confirmButtonText: '确定',
          callback: action => {
            this.$message({
              type: 'info',
              message: '关闭'
            });
          }
        });
    },
    handleCurrentChange(val){
      this.c_page = val
      this.allMsg()
    },
    allMsg() {
      axios.get('/task/all_msg?page='+this.c_page + '&status=' + this.status,
      {headers:{token:localStorage.getItem('token')||''}}).then((resp) => {
        console.log(resp)
        this.msg_list = resp.data.data
        this.c_page = resp.data.c_page
        this.total = resp.data.num
      }).catch((err) => {
        console.log(err)
      });
    },
  }

}
</script>

<style scoped>

</style>