<template>
  <div class="about">
    <h3>项目管理</h3>
    <div class="content">
        <div class="code_content">
            <el-input
            type="textarea"
            :rows="10"
            placeholder="代码编辑"
            v-model="code_content">
            </el-input>
        </div>
        <div class="task_name">
            <el-input v-model="task_name" placeholder="任务名"></el-input>
        </div>
        <div class="button">
            <el-upload
              class="upload"
              ref="upload"
              action="http://127.0.0.1:5000/task/upload"
              :on-preview="handlePreview"
              :on-remove="handleRemove"
              :before-remove="beforeRemove"
              :on-success="handleSuccess"
              :show-file-list="false"
              multiple
              :limit="1"
              :file-list="fileList"
              >
              <el-button type="primary">点击上传</el-button>
            </el-upload>
            <el-button type="primary" icon="el-icon-s-promotion" @click="createTask" class="create">创建</el-button>
            
        </div>
    </div>
  </div>
</template>


<script>
import axios from 'axios';
// @ is an alias to /src


export default {
  name: 'HomeView',
  components: {


  },
  data(){
    return{
        task_name:"",
        code_content:"",
        fileList: []
    }
  },
  mounted(){

  },
  methods:{
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handleSuccess(response, file, fileList){
      console.log(response)
      if(response.status==200){
        this.code_content = response.code
        this.$refs.upload.clearFiles()
      }
      
    },
    handlePreview(file) {
      console.log(file);
      this.fileList.push(file)
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${ file.name }？`);
    },



    createTask(){
      axios.post('/task/create_task',{task_name:this.task_name, code:this.code_content},
      {headers:{token:localStorage.getItem('token')||''}}).then((resp) => {
        console.log(resp)
        if(resp.data.status == 200){
          this.$message({
            message: '任务创建成功!',
            type: 'success'
          });
          this.$router.push('/home/subview3')
        }else{
          this.$message.error("任务创建失败!")
        }
      }).catch((err) => {
        console.log(err)
      });
    },
    handleSelect(key) {
      
    },
  }

}
</script>

<style scoped>
.button{
  margin: 0 auto;
  width: 200px;
}
.upload{
  float: left;
}
.create{
  float: left;
}
.content{
  box-shadow: 0 2px 4px rgba(0, 0, 0, .4), 0 0 6px rgba(0, 0, 0, .1);
  margin: 10px;
  padding: 5px;
  width: 1000px;
  height: 500px;
}
.nav{
  width: 200px;
}
.code_content{
    width: 80%;
    margin: 10px auto;
}
.task_name{
    width: 80%;
    margin: 10px auto;
}


</style>

