import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user:{},
    login_flag:false,//登录弹框标志位
    reg_flag:false,//注册弹框标志位
  },
  getters: {
  },
  mutations: {
    setUser(state, u){
      console.log("保存用户信息成功:", u)
      state.user = u;
    },
    setLoginFlag(state, f){
      state.login_flag = f;
    },
    setRegFlag(state, f){
      state.reg_flag = f;
    },
  },
  actions: {
  },
  modules: {
  }
})
