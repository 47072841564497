<template>
  <div style="display: flex">
    <el-button @click="connectToSSE">搜索</el-button>
    {{answer_rlt}}
  </div>
</template>
<script>
export default {
  data() {
    return {
      answer_rlt: '',
      eventSource: null,
    };
  },
  created() {
    
  },
  methods: {
    connectToSSE() {
      this.eventSource = new EventSource('http://127.0.0.1:8000/ai/search2/?question=' + '脖子不舒服该如何治疗');
      this.eventSource.onmessage = (event) => {
        const data = JSON.parse(event.data) //将字符串转换成json格式
        if (data.answer !== 'done') { //判断结束标识
            // this.answers.push(data.answer);
            this.answer_rlt += data.answer
        } else {
            this.eventSource.close();
        }
        console.log(event.data)
      };
      this.eventSource.onopen = function() {
        console.log('Connection opened.');
      };
      this.eventSource.onerror = (error) => {
        this.eventSource.close();
        if (this.eventSource.readyState === EventSource.CLOSED) {
          console.log('Connection to server closed');
        } else {
          console.error('Error occurred:', error);
        }
      };
    }
  },
  beforeDestroy() {
    if (this.eventSource) {
      this.eventSource.close();
    }
  },
};
</script>