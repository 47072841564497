<template>
  <div>
    <!-- {{mode}}  {{choice}}   -->
    <h2>
        付款界面<span style="font-weight:200;">(微信支付)</span>
    </h2>
    <div class="qrcode">
        <img :src="qrcode" alt="">
    </div>
    <div class="name">
        <span style="color:red; font-weight:bold">共计{{choice.price}}元</span> {{choice.name}}
    </div>
    <!-- {{qrcode}} -->
  </div>
</template>

<script>
import axios from 'axios'

export default {
    data(){
        return{
            mode:this.$route.params.mode,
            choice:this.$route.params.choice,
            qrcode:null,
            tem_poll:null,
        }
    },
    mounted(){
        this.generatePayCode()
    },
    methods:{
        payPoll() {
            // 这里就是请求后端的时候顺便带上这个scene
            axios.get('/ai/wxpay/query/', {headers:{token:localStorage.getItem('token')||''}}).then((resp) => {
                console.log(resp.data);
                if (resp.data.code == 200) {
                    // 清除定时器
                    clearInterval(this.tem_poll)
                    this.$notify({
                        title: '支付成功',
                        type: 'success',
                        duration: 0
                    });
                    
                    let user = JSON.parse(localStorage.getItem('user'))
                    // console.log('支付成功', user)
                    console.log('支付成功', resp.data.data)
                    localStorage.removeItem('user')
                    user.vip = resp.data.data.vip
                    user.vip_end_time = resp.data.data.vip_end_time
                    // console.log('支付成功2', user)
                    localStorage.setItem('user', JSON.stringify(user))
                    this.$store.commit('setUser', user)
                    this.$router.push('/')
                    
                }
            })
            .catch(err => {
                console.log(err)
            })
        },
        generatePayCode(){
            let body = {
                total_amount:this.choice.price*100,
                description: this.choice.name,

            }
            axios.post('/ai/wxpay/',body, 
                {headers:{token:localStorage.getItem('token')||''}}
            )
            .then(resp=>{
                console.log(resp)
                if(resp.data.code == 200){
                    this.qrcode = resp.data.data.qrcode;

                    this.tem_poll = setInterval(this.payPoll, 1000)
                }
            })
            .catch(err=>{
                console.log(err)
            })
            
        }
    }
}
</script>

<style lang="scss" scoped>
.qrcode{
    width: 300px;
    height: 300px;
    margin: 0 auto;
    img{
        width: 100%;
        height: 100%;
    }
}
.name{
    width: 300px;
    height: 70px;
    margin: 15px auto;
    text-align: center;
}

</style>