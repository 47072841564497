<template>
  <div class="home">
    <div>
      <!-- <div>主页</div> -->
      <el-row>
        <el-col :span="8" class="aside">
          <h2 style="color:white; text-align:center;">
            智能检测平台
          </h2>
          <el-menu
            default-active="1"
            class="nav"
            @select="handleSelect"
            background-color="#304156"
            text-color="#fff"
            active-text-color="#409EFF">
            <el-menu-item index="1">
              <i class="el-icon-s-home"></i>
              <span>首页</span>
            </el-menu-item>
            <el-menu-item index="2">
              <i class="el-icon-folder"></i>
              <span>项目管理</span>
            </el-menu-item>
            <el-menu-item index="3">
              <i class="el-icon-s-order"></i>
              <span>应用列表</span>
            </el-menu-item>
            <el-menu-item index="4">
              <i class="el-icon-s-marketing"></i>
              <span>监控管理</span>
            </el-menu-item>
            <el-menu-item index="5">
              <i class="el-icon-setting"></i>
              <span>节点列表</span>
            </el-menu-item>
            <el-menu-item index="8">
              <i class="el-icon-time"></i>
              <span>定时任务</span>
            </el-menu-item>
            <el-menu-item index="9">
              <i class="el-icon-user-solid"></i>
              <span>用户中心</span>
            </el-menu-item>
          </el-menu>
        </el-col>
        <el-col :span="16" class="main">
        
          <router-view/>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src


export default {
  name: 'HomeView',
  components: {

  },
  data(){
    return{
      user:{},
      sub_url:"",
    }
  },
  mounted(){
    this.user = JSON.parse(localStorage.getItem('user'))
    console.log(this.user)
  },
  methods:{
    handleSelect(key) {
      let tmp_url = '/home/subview' + String(key)
      console.log(tmp_url, this.sub_url)
      if(tmp_url == this.sub_url){
        return
      }
      this.sub_url = tmp_url
      this.$router.push(tmp_url)
    },
  }

}
</script>

<style scoped>

.aside{
  background-color: #304156;
  width: 200px;
  text-align: left;
  height: 800px;
}
.nav{
  width: 200px;
}

</style>
