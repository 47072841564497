<template>
  <div>
    <h1>菜单1</h1>
    <!-- {{index_data}} -->
    <div class="head">
      <el-button type="primary" icon="el-icon-user" class="btn">用户数:{{index_data.user_count}}</el-button>
      <el-button type="success" icon="el-icon-circle-check" class="btn" @click="TaskAll">任务数:{{index_data.task_count}}</el-button>
      <el-button type="info" icon="el-icon-s-platform" class="btn" @click="TaskRunAll">运行任务次数:{{index_data.msg_count}}</el-button>
      <el-button type="danger" icon="el-icon-circle-close" class="btn" @click="TaskError">报错信息:{{index_data.err_count}}</el-button>
      <el-button type="warning" icon="el-icon-s-opportunity" class="btn" @click="ExprotFile">结果导出</el-button>
    </div>

    <div id="main" style="width: 1200px; height: 400px; margin:0 auto;"></div>

    <div class="everyday">
        <div id="pie" style="width: 400px; height: 400px; margin:0 auto;"></div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data(){
    return{
      user:{},
      index_data:{},
    }
  },
  mounted(){
    // this.drawChart()
    this.getIndex()
  },
  methods:{
    TaskAll(){
      this.$router.push('/home/subview3' )
    },
    TaskRunAll(){
      this.$router.push('/home/subview4' )
    },
    TaskError(){
      this.$router.push({path:'/home/subview4' , query: {status:0}})
    },
     drawChart(x_data, y_data1, y_data2) {
      // 基于准备好的dom，初始化echarts实例  这个和上面的main对应
      let myChart = this.$echarts.init(document.getElementById("main"));
      // 指定图表的配置项和数据
      let option = {
        title: {
          text: "每日统计",
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: { type: 'cross' }
        },
        legend: {
          data: ["任务总数","错误任务总数"],
        },
        xAxis: {
          data: x_data,
          axisTick: {
            alignWithLabel: true
          },
        },
        yAxis: [
          {
            name: "任务总数",
            type: "value",
            position: 'left',
            min: 0,
            max: 20,
            axisLabel: {
              formatter: '{value}'
            }
          },
          {
            name: "错误任务总数",
            type: "value",
            position: 'right',
            min: 0,
            max: 20,
            axisLabel: {
              formatter: '{value}'
            }
          },
        ],
        series: [
          {
            name: "任务总数",
            type: "line",
            position: 'left',
            data: y_data1,
            min: 0,
            max: 20,
            axisLabel: {
              formatter: '{value}'
            }
          },
          {
            name: "错误任务总数",
            type: "line",
            position: 'right',
            data: y_data2,
            min: 0,
            max: 20,
            axisLabel: {
              formatter: '{value}'
            }
          },
        ],
      };
      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
    },


    drawChart2(success, error) {
      // 基于准备好的dom，初始化echarts实例  这个和上面的main对应
      let myChart = this.$echarts.init(document.getElementById("pie"));
      myChart.resize({
        width: 400,
        height: 300
      });
      // 指定图表的配置项和数据
      let option = {
        title: {
          text: "状态展示",
        },


        tooltip: {
          // trigger: 'axis',
          // axisPointer: { type: 'cross' }
        },
        legend: {
          // data: ["任务总数","错误任务总数"],
        },
      


        series: [
          {
            type: 'pie',
            color: [
              '#759aa0',
              '#dd6b66',
            ],
            data: [
              {
                value: success,
                name: '已完成'
              },
              {
                value: error,
                name: '错误'
              },
            ]
          }
        ],
      };
      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
    },

    getIndex(){
      axios.get('/task/index', {headers:{token:localStorage.getItem('token')||''}})
      .then((resp) => {
        console.log(resp)
        if(resp.data.status==200){
          this.index_data = resp.data.data
          this.drawChart(resp.data.x_axis, resp.data.msg_list, resp.data.err_list)
          this.drawChart2(this.index_data.msg_count-this.index_data.err_count, this.index_data.err_count)
        }
      }).catch((err) => {
        console.log(err)
      });
    },
    ExprotFile(){
      axios.post('/task/export_file', {}, {headers:{token:localStorage.getItem('token')||''}})
      .then((resp) => {
        console.log(resp)
        this.$message({
            message: '结果导出!',
            type: 'success'
          });
      }).catch((err) => {
        console.log(err)
      });
      const fileUrl = 'http://127.0.0.1:5000/static/result.xls'// 文件的URL地址
      const link = document.createElement('a');
      link.href = fileUrl;
      link.setAttribute('download', 'result.xls');
      link.click();
    }
  },

}
</script>

<style>
.head{
  padding: 5px;
  margin: 10px;
  /* box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1) */
}
.btn{
  width: 200px;
  height: 60px;
  font-size: 20px;
}

.everyday{
  width: 90%;
  height: 300px;
  margin-left: 10%;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1)
}

</style>