<template>
    <div>
        <el-dialog
        :visible.sync="flag"
        width="500px"
        center>
            <el-tabs v-model="activeName" @tab-click="handleClick" style="text-align:center">
                <el-tab-pane label="微信扫码" name="0">
                    <!-- <el-tooltip
                    slot="label"
                    effect="dark"
                    content="微信扫码登录"
                    placement="bottom">
                    </el-tooltip> -->
                    <div class="wxlogin" id="wxin">
                    </div>
                </el-tab-pane>
                <el-tab-pane label="账密登录" name="1">
                    <div class="login">
                        <h2>用户登录</h2> <br>
                        <el-form :model="login_user" status-icon :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm"
                        style="width:400px; margin:0 auto;">
                            <el-form-item label="用户名:" prop="username">
                                <el-input v-model="login_user.username" autocomplete="off" placeholder="请输入用户名或手机号"></el-input>
                            </el-form-item>
                            <el-form-item label="密码:" prop="password">
                                <el-input type="password" v-model="login_user.password" autocomplete="off"></el-input>
                            </el-form-item>
                            <el-form-item label="验证码:" prop="code" style="height:50px" >
                                <el-input v-model="login_user.code" autocomplete="off" style="width:200px; float:left;"></el-input>
                                <div>
                                    <img class='imageCode' v-bind:src="iamgeCodeUrl" alt="图形验证码" @click="genImageCode">
                                </div>
                            </el-form-item>
                            <el-form-item>
                                <el-button @click="flag=false">取 消</el-button>
                                <el-button @click="Login('ruleForm')">登录</el-button>
                            </el-form-item>
                        </el-form>
                    </div>
                </el-tab-pane>
               
                <el-tab-pane label="手机验证码登录" name="2">
                    <div class="login">
                        <h2>用户登录</h2> <br>
                        <el-form :model="login_user" status-icon :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm"
                        style="width:400px; margin:0 auto;">
                            <el-form-item label="手机号:" prop="mobile">
                                <el-input v-model="login_user.mobile" autocomplete="off" placeholder="请输入手机号"></el-input>
                            </el-form-item>
                            <el-form-item label="验证码:" prop="sms_code" style="height:50px" >
                                <el-input v-model="login_user.sms_code" autocomplete="off" style="width:170px; float:left;"
                                placeholder="请输入4位验证码"></el-input>
                                <div>
                                    <!-- <button class="sms_btn" @click="getSmsCode">获取验证码</button> -->
                                    <el-button @click="getSmsCode">获取验证码</el-button>
                                </div>
                            </el-form-item>
                            <el-form-item>
                                <el-button @click="flag=false">取 消</el-button>
                                <el-button @click="LoginWithSms('ruleForm')">登录</el-button>
                            </el-form-item>
                        </el-form>
                    </div>
                </el-tab-pane>
            </el-tabs>
        
        </el-dialog>
    </div>
</template>

<script>
import {mapState,mapMutations} from 'vuex'
// 生成uuid
import {v4 as uuid4} from 'uuid'
import axios from 'axios'
import "../utils/wxlogin"
export default {
    data(){
        var validateMobile = (rule, value, callback) => {
            if (!value) {
                return callback(new Error('手机号不能为空'));
            }
            let usernameRule = /^1[3-9]\d{9}$/
            if(!usernameRule.test(value)){
                return callback(new Error('请输入正确的手机号'));
            }
            return callback()
        };
        var validateSmsCode = (rule, value, callback) => {
            if (!value) {
                return callback(new Error('验证码不能为空'));
            }
            let usernameRule = /^\d{4}$/
            if(!usernameRule.test(value)){
                return callback(new Error('请输入4为数字验证码'));
            }
            return callback()
        };
        var validateUsername = (rule, value, callback) => {
            if (!value) {
            return callback(new Error('用户名不能为空'));
            }
            let usernameRule = /^\w{5,15}$/
            if(!usernameRule.test(value)){
                return callback(new Error('用户名由5-15位字母数字组成'));
            }
            return callback()
        };
        var validateCode = (rule, value, callback) => {
            if (!value) {
            return callback(new Error('验证码不能为空'));
            }
            let r = /^\w{4}$/
            if(!r.test(value)){
                return callback(new Error('验证码格式不正确'));
            }

            axios.post('/user/img/'+this.imageCodeID + '/', {code:this.login_user.code})
            .then(resp=>{
                console.log(resp)
                if(resp.data.code == 200){
                    // this.$message({message: resp.data.msg,type: 'success'});
                    callback()
                }else{
                    // this.$message.error(resp.data.msg);
                    callback(new Error(resp.data.msg));
                }
            })
            .catch(err=>{
                console.log(err)
            })
            
        };
        var validatePassword = (rule, value, callback) => {
            if (!value) {
            return callback(new Error('密码不能为空'));
            }
            let mobileRule = /^\w{5,15}$/
            if(!mobileRule.test(value)){
                return callback(new Error('密码由5-15位字母数字组成'));
            }

            return callback()
        };
        return{
            send_time:'',
            login_img:'',
            scene:'',
            activeName: '0', // 0普通  1微信扫码
            imageCodeID: "",//即生成的uuid
            iamgeCodeUrl: "", //图形验证码的地址,
            flag:false,
            tem_poll:'',
            login_user: {
                username: '',
                password: '',
                code: '',
                sms_code:'',
                mobile:'',
            },
            rules: {
                username: [
                    { validator: validateUsername, trigger: 'blur' }
                ],
                password: [
                    { validator: validatePassword, trigger: 'blur' }
                ],
                code: [
                    { validator: validateCode, trigger: 'blur' }
                ],
                mobile: [
                    { validator: validateMobile, trigger: 'blur' }
                ],
                sms_code: [
                    { validator: validateSmsCode, trigger: 'blur' }
                ],
            }
        }
    },
    mounted(){
        this.genImageCode();
    },
    computed:{
        ...mapState(['login_flag']),//映射登录标记位
    },
    methods:{
        wechatLogin(){
            // console.log('微信扫码登录');
            var obj = new WxLogin({
                self_redirect:false,
                id:"wxin", 
                appid: "wxcf68593de8ca98c5", 
                scope: "snsapi_login", 
                redirect_uri: "http://www.biocodehub.com/user/wxtoken",
                state: "STATE",
                style: "",
                href: ""
            });
        },
        getSmsCode(){
            if(this.login_user.mobile.length != 11){
                this.$message.error('请输入正确的手机号');
                return
            }
            // 防止短时间内多次发送短信验证码
            if(this.send_time && (new Date().getTime() - this.send_time) < 60000){
                this.$message.error('请勿频繁发送验证码');
                return
            }
            //获取当前时间
            this.send_time = new Date().getTime()
            axios.post('/user/sms/', {mobile: this.login_user.mobile})
            .then(resp=>{
                console.log(resp);
                if(resp.data.code==200){
                      this.$message({
                          message: '验证码已发送',
                          type: 'success'
                      });
                }
            })
            .catch(err=>{
                console.log(err)
            })
        },
        handleClick(tab, event) {
            console.log(this.activeName)
            console.log(tab, event);
            if(tab.name == '0'){
                // 微信扫码登录
                // this.wechatLogin();

                // 公众号扫码登录
                // console.log('微信扫码登录');
                // axios.get('/user/wxlogin/')
                // .then(resp=>{
                //     console.log(resp)
                //     this.login_img = resp.data.url
                //     this.scene = resp.data.scene
                // })
                // .catch(err=>{
                //     console.log(err) 
                // })
                // this.tem_poll = setInterval(this.loginPoll, 1000)
            }
        },

        loginPoll() {
            // 这里就是请求后端的时候顺便带上这个scene
            axios.get('/user/wxverify?scene=' + this.scene).then((resp) => {
                console.log(resp.data);
                if (resp.data.code == 200) {
                    // 清除定时器
                    clearInterval(this.tem_poll)
                    this.$notify({
                        title: '登录成功',
                        type: 'success',
                        duration: 0
                    });
                    let userinfo = resp.data.user
                    let token = resp.data.token
                    localStorage.setItem('user', JSON.stringify(userinfo))
                    localStorage.setItem('token', token)
                    this.$store.commit('setUser', userinfo)
                    this.flag=false
                    this.$router.push('/')
                    return;
                }
            })
        },
        // 生成图片验证码地址
        genImageCode(){
            // 生成一个uuid
            this.imageCodeID = uuid4()
            // 生成一个图片验证码地址
            this.iamgeCodeUrl = "/user/img/" + this.imageCodeID + "/"
        },
        Login(formName) {
            axios.post("user/login", this.login_user)
            .then(resp=>{
                console.log(resp)
                if(resp.data.code == 200){
                    this.login_user = {}
                    this.$message({message: resp.data.msg,type: 'success'});
                    let userinfo = resp.data.user
                    let token = resp.data.token
                    localStorage.setItem('user', JSON.stringify(userinfo))
                    localStorage.setItem('token', token)
                    this.$store.commit('setUser', userinfo)
                    this.flag=false
                    this.$router.push('/')
                }else{
                    this.$message.error(resp.data.msg);
                }
            })
            .catch(err=>{
                console.log(err)
            })
        },

        LoginWithSms(formName) {
            this.$refs[formName].validate((valid) => {
            if (valid) {
                axios.post("user/smslogin/", this.login_user)
                .then(resp=>{
                    console.log(resp)
                    if(resp.data.code == 200){
                        this.login_user = {}
                        this.$message({message: resp.data.msg,type: 'success'});
                        let userinfo = resp.data.user
                        let token = resp.data.token
                        localStorage.setItem('user', JSON.stringify(userinfo))
                        localStorage.setItem('token', token)
                        this.$store.commit('setUser', userinfo)
                        this.flag=false
                        this.$router.push('/')
                    }else{
                        this.$message.error(resp.data.msg);
                    }
                })
                .catch(err=>{
                    console.log(err)
                })
            } else {
                console.log('error submit!!');
                return false;
            }
            });
        },
        ...mapMutations(['setLoginFlag']),
        
    },
    watch:{
        login_flag: function(v){
            this.flag = v;
        },
        flag:function(v){
            console.log(v);
            this.setLoginFlag(v);
            
            if(v && this.activeName == '0'){
                setTimeout(()=>{
                    this.wechatLogin();
                },200)
            }
        }
    }
}
</script>

<style>
.login{
    text-align: center;
}
.imageCode{
    width: 90px;
    height: 90%;
}

.login_img{
    width: 300px;
}

.sms_btn {
    height: 40px;
    width: 100px;
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid grey;
}
.sms_btn:hover{
    background-color: #ebeced;
    cursor: pointer;
}
</style>