<template>
  <div id="app" data-theme='light'>
    <nav style="height:80px">
      <!-- <div style="float:left">
        <img src="/imgs/logo2.png" alt="" style="width:150px">
      </div> -->
      <div class="title">
        <div class="logo1" @click="gotoHome"></div>
      </div>
      <ul class="home_nav">
        <li><el-link type="primary" @click="gotoHome">首页</el-link></li>
        <li><el-link type="primary" @click="gotoVisual">可视化</el-link></li>
        <li><el-link type="primary" @click="gotoUs">关于我们</el-link></li>
        <li><el-link type="primary" @click="gotoVip">成为会员</el-link></li>
      </ul>
      <ul class="home_nav" v-if="!$store.state.user.username" style="float:right;">
        <li><el-link type="primary" @click="showLoginForm">登录</el-link></li>
        <li><el-link type="primary" @click="gotoRegister">注册</el-link></li>
      </ul>

      <ul class="home_nav" v-else  style="float:right;">
        <li><img :src="$store.state.user.avatar" alt="" class="avatar"></li>
        <li>
          <el-popover
            placement="top"
            width="160"
            v-model="visible">
            <p>您确认要退出登录吗?</p> 
            <div style="text-align: right; margin: 0">
              <el-button size="mini" type="text" @click="visible = false">取消</el-button>
              <el-button type="primary" size="mini" @click="userLogout">确定</el-button>
            </div>
            <el-link type="primary" slot="reference">
              你好,{{$store.state.user.username}}
              <span v-if="$store.state.user.vip" style="color:red;font-size:16px;margin-left:2px">VIP</span>
            </el-link>
          </el-popover>
        </li>
        
        <li>
          <el-link type="primary" @click="userCenter">个人中心</el-link>
        </li>
      </ul>

      <div style="clear:both;"></div>

      <!-- <div class="theme" @click="changeTheme">
        <i class="el-icon-sunny" v-if="theme_flag==1"></i>
        <i class="el-icon-moon" v-else></i>
      </div> -->
    </nav>
    <hr>
    <login-comp/>
    <register-comp/>
    <router-view/>
  </div>
</template>

<script>
import LoginComp from './components/LoginComp.vue';
import RegisterComp from './components/RegisterComp.vue';

// import {mapState} from "vuex";

export default {
  components: { LoginComp, RegisterComp },
  // components:{
  //   leftnav,
  // },
  data() {
    return {
      visible:false,
      theme_flag:1, //1亮色  2暗色
      theme_name:'light',
    };
  },
  created() {
    document.documentElement.setAttribute('data-theme', 'light')
    let user = localStorage.getItem('user')
    if(user){
      let user_obj = JSON.parse(user)
      this.$store.commit('setUser', user_obj)
    }
  },
  computed: {
    
  },
  watch: {
    theme_flag:function(v){
      console.log(v);
      if(v==1){
        this.theme_name = 'light'
      }else{
        this.theme_name = 'dark'
      }
      document.documentElement.setAttribute('data-theme',this.theme_name)
    }
  },

  methods: {
    gotoHome(){
      this.$router.push('/')
    },
    changeTheme(){
      if(this.theme_flag==1){
        this.theme_flag=0;
      }else{
        this.theme_flag=1;
      }
    },
    showLoginForm(){
      this.$store.commit('setLoginFlag', true)
      this.visible=false
    },
    gotoVip(){
      this.$router.push('/vip')
    },
    gotoVisual(){
      this.$router.push('/visual')
    },
    gotoUs(){
      this.$router.push('/about')
    },
    gotoHome(){
      this.$router.push('/')
    },
    gotoRegister(){
      this.$router.push('/register')
    },
    userLogout(){
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      this.$store.commit('setUser','');
      this.$router.push('/');
    },
    userCenter(){
      this.$router.push('/usercenter');
    },
    userMessages(){
      this.$router.push('/usermessage');
    }
  },
};
</script>


<style lang="scss" scoped>
// @import "@/style/common.scss";
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  height: 1000px;
  /* background: rgb(9, 9, 11); */
  @include background_color('background_color1');
  @include font_color('font_color1');

}

nav {
  padding: 5px;
}

nav a {
  font-weight: bold;
  @include font_color('font_color1');
}

nav a.router-link-exact-active {
  color: #42b983;
}

.home_nav{
  float: left;
  /* text-decoration: none; */
  list-style-type: none;
  margin-right: 50px;
}



.home_nav li{
  float: left;
  /* width: 50px; */
  margin-right: 20px;
  text-align: center;
  line-height: 40px;
}

.title{
  margin: 0 50px;
  float: left;
  height: 50px;
  line-height: 50px;
  font-size: 20px;
  color: rgb(35, 135, 194);
}

.theme{
  position: absolute;
  left: 30px;
  bottom: 100px;
  height: 40px;
  width: 40px;
  border-radius: 25px;
  // border: 1px solid grey;
  cursor: pointer;
  line-height: 48px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.3);
  i {
    font-size: 25px;
  }
}

.logo1{
  margin: 10px;
  width: 260px;
  height: 50px;
  cursor: pointer;
  @include content('logo_image');
}

.avatar{
  width: 40px;
  height: 40px;
  border-radius: 15px;
}
</style>
