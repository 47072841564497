<template>
  <div>
    <div class="title">
        套餐以及价格说明
    </div>
    <div class="price">
        <div class="price_select" v-for="(item, i) in price_list" :key=i>
            <h3>{{item.name}}</h3>
            <p style="height:240px;">{{item.content}}</p>
            <h4 style="color:red">￥{{item.price.toFixed(2)}}</h4>
            <el-button v-if="item.price != 0" @click="buyGood(item)">购买套餐</el-button>
            <el-button v-else @click="addGroup()">申请加群</el-button>
        </div>
    </div>


    <el-dialog
    title="商品支付"
    :visible.sync="dialogVisible"
    width="500px"
    :before-close="handleClose"
    style="text-align:left;">
        <div style="margin:10px">
            <span style="font-weight:900">需要支付:</span>
            <span style="color:red">￥{{choice.price}}</span>
        </div>
        <div style="margin:10px">
            <span style="font-weight:900">套餐名称:</span>
            <span>{{choice.name}}</span>
        </div>
        <div style="margin:10px">
            <span style="font-weight:900">套餐描述:</span>
            <span>{{choice.content}}</span>
        </div>
        <div style="text-align:center; margin:30px auto; line-height:50px;">
            <el-radio v-model="radio" label="1">
                微信支付
                <img src="/static/logo/wxpay.png" alt="" width="30px">
            </el-radio>
            <el-radio v-model="radio" label="2">
                支付宝支付
                <img src="/static/logo/alipay.png" alt="" width="30px">
            </el-radio>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="gotoPay">立即支付</el-button>
        </span>
    </el-dialog>

    <el-dialog
    title="欢迎加群"
    :visible.sync="add_flag"
    width="30%"
    :before-close="closeAdd">
    <div>
        <img src="/static/picture/wxq.png" alt="" style="width:200px; height:200px;">
    </div>
    <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="add_flag = false">确 定</el-button>
    </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
    data(){
        return{
            add_flag:false,
            price_list:[
                {price:0.00, name:'网站试用期', content:'目前为网站试运营期，欢迎大家积极注册，免费体验当前的试运营服务！为了提供更优质的生物信息学和人工智能科研工具，未来可能会因开发及运营成本等因素推出部分付费功能。现阶段，我们诚邀您扫码添加下方“小管家”微信，加入BCH学习群，与我们团队的专业成员共同探讨新的科研趋势、交流技术心得，共同成长。期待与您在知识的旅程中携手同行！'},
                {price:49.00, name:'VIP大会员服务', content:'限时推出先锋会员服务，仅49元（名额有限）！网站的制作和运营离不开您的支持，您的加入不仅是对我们团队的鼓励，也将助力平台的进一步开发。在您付费成为VIP先锋会员后，您将获得 BioCode Hub大语言模型的增强模式体验权限，同时可点击左侧“申请加群”按钮，添加“小管家”微信，凭注册账号申请加入VIP先锋会员群。在先锋会员群中，团队成员将为您提供专业的生信技术支持和答疑服务，努力为您解决科研过程中碰到的生物信息学代码问题。此外，先锋会员可优先体验未来上线的生信及AI教学内容以及后期AI工具，让您始终领先一步！期待与您共同推动生物信息学和人工智能的创新发展！'},
                // {price:318.00, name:'年度特惠套餐', content:'套餐包含8800积分通用AI模型、1699积分增强AI模型......'},
            ],
            choice:{},
            dialogVisible: false,
            radio: '1',
        }
    },

    methods: {
    closeAdd(){
        this.add_flag = false
    },
    addGroup(){
        this.add_flag = true
     },
      handleClose(done) {
        this.$confirm('确认关闭？')
          .then(_ => {
            done();
          })
          .catch(_ => {});
      },
      buyGood(item){
        this.dialogVisible = true
        this.choice = item
      },
      gotoPay(){
        if (this.radio == '1'){
            // 微信支付
            this.dialogVisible = false;
            this.$router.push({name:'WxPay', params:{mode:this.radio, choice:this.choice}})
        }else{
            // 支付宝支付
            this.dialogVisible = false;
            this.$router.push({name:'Pay', params:{mode:this.radio, choice:this.choice}})
        }
        
      }
    }
}
</script>

<style lang="scss" scoped>

.title{
    margin: 20px auto;
    font-size: 30px;
    /* font-weight: bolder; */
}

.price{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    width: 90%;
    margin: 0 auto;
    
    .price_select{
        width: 400px;
        height: 450px;
        margin: 20px;
        padding: 20px;
        // background-color: antiquewhite;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        p{
            text-align: left;
            text-indent: 2em;
        }
        .el-button{
            float: right;
        }
    }
}
</style>