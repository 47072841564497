<template>
  <div class="main">
    <div class="head">
    </div>
    <div class="right" :style="{width: right_width}">
      <div class="functions">
        <!-- <el-button-group>
          <el-button style="width:100px">简洁</el-button>
          <el-button style="width:100px">增强</el-button>
          <el-button style="width:100px">研究</el-button>
        </el-button-group> -->

        <div class="func_item"><div @click="mode=0" :style="{background:mode_color[0]}">简洁搜索</div></div>
        <div class="func_item"><div @click="mode=1" :style="{background:mode_color[1]}">增强模式</div></div>
        <!-- <div class="func_item"><div @click="mode=2" :style="{background:mode_color[2]}">研究</div></div> -->
        <div class="clear"></div>
      </div>

      <div class="answer_block">
        <!-- {{answers_list}}
        {{answers_list.length}} -->

        <!-- <div class="anwser1">
          BioCode Hub
          <i class="el-icon-copy-document copy" @click="copyAnswer"></i>
          <i class="el-icon-star-off star"  @click="addCollect"></i>
        </div >  -->
        <div class="add_collect">
          
          <i class="el-icon-star-off star"  @click="addCollect">收藏</i>
        </div>
        <div  v-for="(item, i) in answers_list" :key="i" class="answer_show">
          <!-- <div class="anwser2" v-if="item.text">
            {{item.text}}
          </div> -->
          <vue-markdown :source="item.text" style="text-align:left;"></vue-markdown>
          <code-edit :pom="item.code" v-if="item.code"></code-edit>
        </div>
        
      </div>
      <div class="stop" v-if="answer_rlt">
        <button icon="el-icon-video-pause" class="stop_btn" @click="stopAnswer">停止响应</button>
      </div>
      <!-- <div class="mode">
        <el-button-group>
          <el-button type="primary">普通搜索</el-button>
          <el-button type="primary">高级搜索</el-button>
        </el-button-group>
      </div> -->
      <div class="question">
        
        <div  class="question_input">
          <textarea name="" id="" rows="3" placeholder="请输入内容" v-model="question"></textarea>
        </div>
        
        <div class="question_btn">
          <el-button icon="el-icon-s-promotion" type="primary" @click="connectToSSE">搜索</el-button>
          <!-- <el-button icon="el-icon-s-promotion" type="primary" @click="searchAI2">搜索2</el-button> -->
        </div>

        <div class="clear"></div>
      </div>

    </div>
    <div class="clear"></div>

  </div>
</template>

<script>
import CodeEdit from '../components/CodeEdit.vue'
import VueMarkdown from 'vue-markdown';
import axios from 'axios';
export default {
    components:{
      CodeEdit,
      VueMarkdown,
    },
    data() {
      return {
        question:this.$route.params.question,
        right_width:'50%',
        mode_color:['#dae9ff', '#ffffff', '#ffffff'],
        mode:this.$route.params.mode, //0简洁 1增强 2研究
        answer:'# Hello, Vue Markdown! \n',
        eventSource: null,
        answer_rlt:'',
        eventSource: null,
        code_start:false,
        answers_list:[
          {text:'', code:''},
        ]
      };
    },
    created(){
        if(this.mode==0){
          this.mode_color = ['#dae9ff', '#ffffff', '#ffffff']
        }else if(this.mode==1){
          this.mode_color = ['#ffffff', '#dae9ff', '#ffffff']
        }else{
          this.mode_color = ['#ffffff', '#ffffff', '#dae9ff']
        }

        if(this.question){
          this.connectToSSE()
        }
    },
    beforeDestroy() {
        if (this.eventSource) {
        this.eventSource.close();
        }
    },
    watch: {
      mode:function(v){
        console.log(v);
        if(v==0){
          this.mode_color = ['#dae9ff', '#ffffff', '#ffffff']
        }else if(v==1){
          this.mode_color = ['#ffffff', '#dae9ff', '#ffffff']
          if(!this.$store.state.user.username){
            this.$message.error({
              message: '用户未登录',
          })
          // this.$store.commit('setLoginFlag', true)
          this.mode = 0
          }else if(!this.$store.state.user.vip){
            this.$message.error({
                message: '当前用户不是会员优惠，无法使用增强搜索',
            })
            this.mode = 0
          }
        }else{
          this.mode_color = ['#ffffff', '#ffffff', '#dae9ff']
        }
      }
    },
    methods: {
      copyAnswer(){

      },
      stopAnswer(){
        this.eventSource.close()
      },
      addHistory(){
        const obj = {
          answer:this.answer_rlt,
          question:this.question
        }
        axios.post('/user/history/',obj,{headers:{token:localStorage.getItem('token')||''}})
            .then((response) => {
                console.log(response)
            }).catch((err) => {
                console.log(err)
            });
      },
      addCollect(){
        if(this.answer_rlt.length<20){
          this.$message.error('没有可以收藏的结果');
          return
        }

        const obj = {
          answer:this.answer_rlt,
          question:this.question
        }
        axios.post('/user/collections/',obj,{headers:{token:localStorage.getItem('token')||''}})
            .then((response) => {
                console.log(response)
                if(response.data.code==200){
                  this.$message.success(response.data.msg)
                }else{
                  this.$message.error(response.data.msg);
                }
            }).catch((err) => {
                console.log(err)
            });
      },
      connectToSSE() {
        this.answers_list = [{text:'', code:''},]
        this.answer_rlt = ''
        this.eventSource = new EventSource('https://www.biocodehub.com/ai/search2/?question=' + this.question + '&mode=' + this.mode);
        this.eventSource.onmessage = (event) => {
          const data = JSON.parse(event.data) //将字符串转换成json格式
          this.answer_rlt += data.answer
          if (data.answer !== 'done') { //判断结束标识
              // this.answers.push(data.answer);
              this.answer += data.answer
              const index = this.answers_list.length - 1  //下标
              const answer_obj = this.answers_list[index]
              // let code_flag = /```/
              // if(data.answer.search(code_flag)>0){
              if(data.answer.indexOf("```")!=-1){
                if(this.code_start == false){
                  this.code_start = true
                  answer_obj.text +=  data.answer.split('```')[0]
                  answer_obj.code += data.answer.split('```')[1]
                }else{
                  this.code_start = false
                  answer_obj.code += data.answer.split('```')[0]

                  this.answers_list.push({text:'', code:''})
                  const new_index = this.answers_list.length - 1
                  const new_answer_obj = this.answers_list[new_index]
                  console.log(new_index)
                  new_answer_obj.text = data.answer.split('```')[1]
                }
                
                console.log(data.answer.split('```'))
              }else{
                if(this.code_start == true){
                  answer_obj.code += data.answer
                }else{
                  answer_obj.text += data.answer
                }
              }
              

          } else {
              this.eventSource.close();
              this.addHistory()
          }
          // console.log(event.data)
        };
        this.eventSource.onopen = function() {
          console.log('Connection opened.');
        };
        this.eventSource.onerror = (error) => {
          this.eventSource.close();
          if (this.eventSource.readyState === EventSource.CLOSED) {
            console.log('Connection to server closed');
          } else {
            console.error('Error occurred:', error);
          }
        };
      },
      gotoVip(){
        this.$router.push('/vip')
      },
      showLoginForm(){
        this.$store.commit('setLoginFlag', true)
        this.visible=false
      },
      searchAI(){
        axios.post('/ai/search1/', {question: this.question, mode: this.mode})
        .then((response) => {
            console.log(response)
            this.answer = response.data.message
        }).catch((err) => {
            console.log(err)
        });
      },
    },
    beforeDestroy() {
    if (this.eventSource) {
      this.eventSource.close();
    }
  },
  }
</script>

<style lang="scss" scoped>

i{
  display: inline-block;
  cursor: pointer;
}
.main{
  height: 800px;
  text-align: center;
}
.head{
  height: 10px;
}
.right{
  // width: 80%;
  height: 800px;
  margin: 0 auto;
}
.clear{
  clear: both;
}
.title{
  font-size: 20px;
  text-align: center;
  margin: 10px;
  height: 50px;
  span{
    position: relative;
    bottom: 10px;
    left: 10px;
  }
}
.btn_dlg{
  text-align: center;
  padding: 5px;
  .btn_item{
    width: 90%;
  }
}
.windows{
  .windows_show{
    width: 90%;
    margin: 0 auto;
  }
}

.upgrade{
  width: 90%;
  margin: 10px auto;
  border: 1px solid rgb(216, 215, 215);
  height: 300px;
  border-radius: 20px;
  .up_title{
    font-weight: bold;
    font-size: 20px;
    margin: 10px;
  }
  .up_content{
    margin: 10px;
    text-align: left;
    text-indent: 2em;
  }
  .up_btn{
    margin: 10px auto;
    width:90%;
  }
}

.issue{
  font-size: 10px;
}

.discounts{
  margin: 10px;
  padding: 10px;
  border-bottom:1px solid rgb(216, 215, 215);
}

.show_content{
  font-size: 25px;
  font-weight: bolder;
}

.anwser1{
  height: 30px;
  width: 80%;
  margin: 0 auto;
  border-bottom: 1px solid white;
  // border-radius: 20px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background: #3d3d3d;
  margin-top:20px ;
  color: white;
  line-height: 30px;
  text-align: left;
  text-indent: 2em;
  padding: 0 10px;

}
.answer_block{
  width: 90%;
  margin: 0 auto;
  // border: 1px solid black;
  height: 600px;
  overflow:auto;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}
.answer_show{
  width: 90%;
  margin: 10px auto;
  // border: 1px solid black;
  text-align: left;
}
.anwser2{
  color: black;
  width: 90%;
  border-radius: 20px;
  background-color: #f7f7fa;
  border: 1px solid rgb(201, 198, 198);
  padding: 10px;
  margin: 10px auto;
  text-indent: 2em;
}

.question{
  height: 100px;
  width: 90%;
  margin: 10px auto;
  // border: 1px solid grey;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  // padding: 10px;
}

.question_input{
  width: 75%;
  padding: 10px 20px;
  float: left; 
  textarea{
    width: 100%;
  }
}
.question_btn{
  float: left;
  width: 15%;
  line-height: 100px;
}

.login_input{
  width: 350px;
}

.functions{
  width: 400px;
  margin: 10px auto;
  padding: 20px;
}
.func_item{
  float: left;
  width: 50%;
  height: 50px;
  div{
    width: 180px;
    margin: 5px auto;
    height: 30px;
    border-radius: 10px;
    line-height: 30px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    font-weight: bold;
    cursor: pointer;
    color: black;
  }
}

.el-button{
  @include background_color('button_background_color1');
  @include font_color('button_color1');
  @include border_color('button_border_color1');
}

.avatar{
  width: 50px;
  height: 50px;
  border-radius: 25px;
}


textarea{
  font-size: 20px;
  border: none;
  outline: none;
  resize: none;
  border-radius: 10px;
  padding: 5px;
}

.add_collect{
  width: 90%;
  margin: 0 auto;
  text-align: right;
  padding: 10px;
}

.stop{
  height: 50px;
  line-height: 60px;

  .stop_btn {
    height: 40px;
    width: 100px;
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid grey;
  }
  .stop_btn:hover{
    background-color: #ebeced;
    cursor: pointer;
  }
}
</style>