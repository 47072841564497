<template>
  <div>
    <h2>
        付款界面<span style="font-weight:200;">(支付宝支付)</span>
    </h2>
    <div v-if="url">
        <iframe :src="url" class="pay-iframe">
        </iframe>   
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
    data(){
        return{
            mode:this.$route.params.mode,
            choice:this.$route.params.choice,
            url:'',
            tem_poll:null,
        }
    },
    mounted(){
        this.generatePayCode()
    },
    methods:{
        payPoll() {
            // 这里就是请求后端的时候顺便带上这个scene
            axios.get('/ai/zfbpay/query/', {headers:{token:localStorage.getItem('token')||''}}).then((resp) => {
                console.log(resp.data);
                if (resp.data.code == 200) {
                    // 清除定时器
                    clearInterval(this.tem_poll)
                    this.$notify({
                        title: '支付成功',
                        type: 'success',
                        duration: 0
                    });
                                        let user = JSON.parse(localStorage.getItem('user'))
                    user.vip = resp.data.vip
                    user.vip_end_time = resp.data.vip_end_time
                    localStorage.setItem('user', JSON.stringify(user))
                    this.$store.commit('setUser', user)
                    this.$router.push('/')
                }
            })
            .catch(err => {
                console.log(err)
            })
        },
        generatePayCode(){
            let body = {
                total_amount:this.choice.price,
                description: this.choice.name,

            }
            axios.post('/ai/order/',body, 
                {headers:{token:localStorage.getItem('token')||''}}
            )
            .then(resp=>{
                console.log(resp)
                if(resp.data.code == 200){
                    this.url = resp.data.data.url
                    // console.log(this.url)
                    this.tem_poll = setInterval(this.payPoll, 1000)
                }
            })
            .catch(err=>{
                console.log(err)
                // console.log('77777777777')
            })
            
        }
    }
}
</script>

<style>
.pay-iframe{
    width: 100%;
    height: 500px;
    /* 不可以拖动 */
    pointer-events: none;
    /* background-color: rgb(250, 250, 250); */
    border: none;
}
</style>