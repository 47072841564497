<template>
  <div>
    用户协议详细内容，待补充。。。。。。
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>