<template>
  <div class="main">
    <div class="left">
        <div class="menu_item" @click="menu_flag=1">个人资料</div>
        <div class="menu_item" @click="menu_flag=2">我的收藏</div>
        <div class="menu_item" @click="menu_flag=3">历史记录</div>
        <div class="menu_item" @click="menu_flag=4">消息中心</div>
        <div class="menu_item" @click="menu_flag=5">问题反馈</div>
    </div>

    <div class="right">
       <user-center v-if="menu_flag==1"></user-center>
       <collections v-if="menu_flag==2"></collections>
       <history v-if="menu_flag==3"></history>
       <feedback v-if="menu_flag==5"></feedback>
    </div>
  </div>
</template>

<script>
import UserCenter from '../components/UserInfo.vue'
import Collections from '../components/Collections.vue'
import History from '../components/History.vue'
import Feedback from '../components/Feedback.vue'
  export default {
    components:{
        UserCenter,
        Collections,
        History,
        Feedback,
    },
    data() {
      return {
        menu_flag:1,
      }
    },
    created(){
      if(this.$route.params.menu){
        console.log(this.$route.params.menu)
        this.menu_flag = this.$route.params.menu
      }
    },
    methods: {
      onSubmit() {
        console.log('submit!');
      }
    }
  }
</script>

<style lang="scss" scoped>
.main{
    width: 80%;
    margin: 0 auto;
    height: 700px;
    // border: 1px solid black;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    .left{
        width: 15%;
        height: 700px;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    }
    .right{
        width: 75%;
        height: 700px;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        margin-left: 20px;
        text-align: left;
        padding-left: 40px;
    }
}

.menu_item{
    width: 100%;
    height: 40px;
    margin: 20px 0;
    font-size: 24px;
    cursor: pointer;
}
.menu_item:hover{
    border-left: 3px solid black;
}

.avatar{
    width: 60px;
    border-radius: 30px;
}

.el-button{
    position: relative;
    bottom: 20px;
    left: 50px;
}

.save_btn{
    width: 100px;
    height: 40px;
    font-size: 18px;
    background-color: black;
    color:white;
    cursor: pointer;
    border-radius: 5px;
}
</style>