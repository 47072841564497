<template>
  <div class="main">
    <div class="nav">
      <div class="title">{{ visual_project.name }}</div>
    </div>
    <div class="content">
      <div class="desc">
        <p v-for="(item, i) in visual_project.desc_list" :key="i">{{ item}}</p>
      </div>
      <div class="img">
        <img :src="visual_project.img" alt="">
      </div>
    </div>
    <div class="code">
      <h3>代码</h3>
      <code-edit :pom="visual_project.code"></code-edit>
     </div>
  </div>
</template>

<script>
import CodeEdit from '../components/CodeEdit.vue'
import axios from 'axios'
export default {
  components:{
    CodeEdit,
  },
  data(){
    return{
      visual_id:this.$route.params.id,
      visual_project:{},
    }
  },
  mounted(){
    if(!this.visual_id){
      this.$router.push('/')
    }

    this.getVisualProject()
  },
  methods:{
    getVisualProject(){ //获取可视化项目数据
      axios.get('/ai/visual/' + this.visual_id + '/').then(res => {
        console.log(res)
        this.visual_project = res.data.data
        this.visual_project.desc_list = this.visual_project.description.split('\n')
        // console.log(this.visual_project.desc_list)
      }).catch(err => {
        console.log(err)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.nav{
  width: 90%;
  height: 40px;
  // border: 1px solid black;  
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  line-height: 40px;
  .title{
    font-size: 18px;
    margin: 0 20px;
    font-weight: bold;
    cursor: pointer;
  }
  .title:hover{
    border-bottom: 2px solid black;
  }
}

.content{
  width: 70%;
  // border: 1px solid black;  
  margin: 20px auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  div{
    width: 35%;
    height: 400px;
    // border: 1px solid black; 
    margin: 0 10px; 
  }
}

.desc{
  text-align: left;
  text-indent: 2em;
}

.img{
  img{
    width: 400px;
    height: 400px;
  }
}

.code{
  width: 60%;
  height: 400px;
  // border: 1px solid black;  
  margin: 20px auto;
  text-align: left;
}
</style>