<template>
  <div>
    <h1>节点管理</h1>
    <div>
      <el-button type="success" @click="dialogVisible = true">添加节点</el-button>
    </div>

    <div>
      <el-dialog
        title="添加新节点"
        :visible.sync="dialogVisible"
        width="30%"
        :before-close="handleClose">
          <el-input v-model="new_ip" placeholder="请输入新节点IP"></el-input>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="addNode">确 定</el-button>
        </span>
      </el-dialog>
    </div>

    <el-table
      :data="node_list"
      style="width: 800px; text-align:center; margin:0 auto;">
      <el-table-column
        label="编号"
        width="100">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.row.id }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="IP地址"
        width="200">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.row.ip_addr }}</span>
        </template>
      </el-table-column>
       <el-table-column
        label="在线状态"
        width="100">
        <template  slot-scope="scope">
          <span style="margin-left: 10px" v-if="scope.row.status==1"><el-tag type="success">在线</el-tag></span>
          <span style="margin-left: 10px" v-if="scope.row.status==0"><el-tag type="danger">离线</el-tag></span>
        </template>
      </el-table-column>
      <el-table-column
        label="空闲状态"
        width="100">
        <template  slot-scope="scope">
          <span style="margin-left: 10px" v-if="scope.row.r_status==1"><el-tag type="success">空闲</el-tag></span>
          <span style="margin-left: 10px" v-if="scope.row.r_status==0"><el-tag type="danger">繁忙</el-tag></span>
        </template>
      </el-table-column>
      <el-table-column
        label="创建时间"
        width="200">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.row.create_time }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="danger"
            @click="handleDelete(scope.$index, scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import axios from 'axios';
// @ is an alias to /src

export default {
  name: 'HomeView',
  components: {


  },
  data(){
    return{
        dialogVisible: false,
        node_list:[],
        new_ip:'',
    }
  },
  mounted(){
    this.allNode()
  },
  watch:{

  },
  methods:{
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          done();
        })
        .catch(_ => {});
    },
    handleDelete(i, row){
      axios.post('/task/delete_node', {node_id: row.id}, 
      {headers:{token:localStorage.getItem('token')||''}}).then((resp) => {
        console.log(resp)
        this.$message({
            message: '删除节点!',
            type: 'success'
          });
          this.allNode()
      }).catch((err) => {
        console.log(err)
      });
    },
    allNode() {
      axios.get('/task/all_node',
      {headers:{token:localStorage.getItem('token')||''}}).then((resp) => {
        console.log(resp)
        this.node_list = resp.data.data
      }).catch((err) => {
        console.log(err)
      });
    },
    addNode(){
      this.dialogVisible = false
      axios.post('/task/create_node', {ip_addr: this.new_ip}, 
      {headers:{token:localStorage.getItem('token')||''}}).then((resp) => {
        console.log(resp)
        this.$message({
            message: '添加节点成功!',
            type: 'success'
          });
          this.allNode()
      }).catch((err) => {
        console.log(err)
      });
    }
  }

}
</script>

<style scoped>

</style>