<template>
    <div>
        <div>
            <h2>用户收藏</h2>
            <!-- {{$store.state.user}} -->
            <!-- {{this.user_info.avatar}} -->
            <!-- {{col_list}} -->
        </div>
          <el-table
            :data="col_list">
            <el-table-column
            label="编号"
            width="150">
            <template slot-scope="scope">
                <span style="margin-left: 10px">{{ scope.row.id }}</span>
            </template>
            </el-table-column>

            <el-table-column
            label="收藏时间"
            width="200">
            <template slot-scope="scope">
                <span style="margin-left: 10px">{{ scope.row.created_time }}</span>
            </template>
            </el-table-column>

            <el-table-column
            label="问题"
            width="300">
            <template slot-scope="scope">
                <span style="margin-left: 10px">{{ scope.row.question }}</span>
            </template>
            </el-table-column>
            
            <el-table-column label="操作">
            <template slot-scope="scope">
                <el-button
                size="mini"
                type="danger"
                @click="deleteCol(scope.$index, scope.row)">删除</el-button>
                <el-button
                size="mini"
                @click="show(scope.row)">查看结果</el-button>
            </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
import axios from 'axios'
  export default {
    data() {
      return {
        col_list:[],
      }
    },
    mounted(){
        this.getUserCollections()
    },
    methods: {
        show(row){
            this.$alert(row.answer, '结果查看', {
                confirmButtonText: '确定',
                callback: action => {
                    // this.$message({
                    // type: 'info',
                    // message: `action: ${ action }`
                    // });
                }
            })
        },
        getUserCollections(){
            axios.get('/user/collections/', {headers:{token:localStorage.getItem('token')||''}})
            .then((response) => {
                console.log(response)
                this.col_list = response.data.collections
            }).catch((err) => {
                console.log(err)
            });
        },
        deleteCol(index, row){
            axios.delete('/user/collections/?collection_id=' + row.uid,{headers:{token:localStorage.getItem('token')||''}})
            .then((response) => {
                this.$message.success(response.data.msg)
                this.getUserCollections()
            }).catch((err) => {
                console.log(err)
            });
        },
        
    }
  }
</script>

<style lang="scss" scoped>

</style>